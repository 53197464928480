.login-wrapper {
	.login-container {
		display: flex;
		flex-wrap: wrap;
		min-height: calc(100vh - 12.3rem);
		@include bp(smd-max) {
			min-height: calc(100vh - 12.2rem);
		}
		@include bp(sm-max) {
			align-items: inherit;
		}
		.slider-wrapper {
			width: 50%;
			position: relative;
			color: $white;
			@include bp(sm-max) {
				display: none;
			}
			.slider-container {
				height: 100%;
			}
			.login-slider {
				height: 100%;
			}
			.swiper-wrapper {
				height: 100%;
			}
			.copyright {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 3.5rem;
				z-index: 1;
				text-align: center;
				.icon {
					display: inline-block;
					line-height: 0;
					width: 1.3rem;
					position: relative;
					top: -0.1rem;
				}
			}
			.login-slider {
				.login-slide {
					position: relative;
					height: 100%;
					z-index: 0;

					&:before {
						content: "";
						@extend .absolute-pos;
						background: linear-gradient(1.12deg, rgba($black, 0.48) 0.96%, rgba($black, 0) 99.04%);
						z-index: 0;
					}
					.slider-thumb {
						@extend .absolute-pos;
						@extend .cover-fit;
						z-index: -1;
					}
					.slide-content {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 12.7rem;
						text-align: center;
						z-index: 1;
						width: 60rem;
						max-width: calc(100% - 3.2rem);
						margin: 0 auto;
						@include bp(lg-max) {
							bottom: 10rem;
						}
						h2 {
							min-height: 8rem;
							margin-bottom: 0;
							@include bp(lg-max) {
								min-height: 12rem;
							}
						}
					}
				}
				.swiper-pagination {
					bottom: 22.6rem;
					@include bp(lg-max) {
						bottom: 24.5rem;
					}
				}
			}
		}
		.content-wrapper {
			width: 50%;
			background-image: url("../images/login-bg-pattern.svg");
			@extend .bg-props;
			padding: 2.3rem 1.6rem;
			display: flex;
			align-items: center;
			justify-content: center;
			@include bp(sm-max) {
				width: 100%;
				min-height: 100%;
				align-items: flex-start;
			}
			.login-form {
				width: 40rem;
				max-width: 100%;
				margin: 0 auto;
				&.sm-width {
					width: 32.8rem;
				}
				.login-title {
					text-align: center;
					margin-bottom: 5.2rem;
					@include bp(sm-max) {
						margin-bottom: 4.3rem;
					}
					h2 {
						font-family: $semiboldFont;
						margin-bottom: 1.4rem;
						@include bp(sm-max) {
							margin-bottom: 0.6rem;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
					p {
						color: $darkGrey;
					}
				}
				.form-group {
					margin-bottom: 1.5rem;
				}
				.forgot-link {
					display: flex;
					justify-content: flex-end;
					margin-top: 1.1rem;
					@include bp(sm-max) {
						margin-top: 1.7rem;
					}
				}
				.agreement-block {
					text-align: center;
					.primary-link {
						font-family: $regularFont;
					}
				}
				.orange-btn {
					width: 100%;
					margin-top: 2.3rem;
					@include bp(sm-max) {
						margin-top: 2.2rem;
					}
				}
				.form-bottom {
					text-align: center;
					margin-top: 1.6rem;
					line-height: 2rem;
				}
			}
		}
	}
}
