.slider-container {
	position: relative;
	.swiper-pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 2rem;
		z-index: 2;
		@include bp(sm-max) {
			bottom: 1.5rem;
		}
		.swiper-pagination-bullet {
			margin: 0 0.6rem 0 0;
			height: 0.7rem;
			width: 0.7rem;
			background: $white;
			opacity: 0.7;
			box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.3);
			@extend .transition;
			border-radius: 2.4rem;
			.rtl & {
				margin-right: 0;
				margin-left: 0.6rem;
			}
			&:last-child {
				margin: 0;
			}
			&.swiper-pagination-bullet-active {
				width: 2.4rem;
			}
		}
	}
}

.swiper-button-next,
.swiper-button-prev {
	display: flex;
	height: 6rem;
	width: 6rem;
	margin: 0;
	top: 50%;
	@include translateY(-50%);
	background-color: $white;
	filter: drop-shadow(0 0.4rem 1rem rgba($black, 0.2));
	border-radius: 50%;
	z-index: 2;
	@extend .transition;
	&.swiper-button-lock {
		cursor: not-allowed;
	}
	&.swiper-button-disabled {
		opacity: 1;
		cursor: not-allowed;
		pointer-events: inherit;
		background-color: $grey;
	}
	&:after {
		display: none;
	}
	&::before {
		content: "";
		@extend .absolute-pos;
		background-image: url("../images/right-arrow.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: 1.2rem;
		.rtl & {
			@include rotate(180deg);
		}
	}
}

.swiper-button-prev {
	&::before {
		@include rotate(180deg);
		.rtl & {
			@include rotate(0deg);
		}
	}
}
