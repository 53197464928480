.account-pages {
	padding: 2.6rem 0 12.4rem;
	@include bp(sm-max) {
		padding: 2rem 0 8.4rem;
	}
	.account-container {
		margin-top: 3.2rem;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		@include bp(sm-max) {
			margin-top: 3.5rem;
		}
		.account-menu {
			width: 29.4rem;
			border-radius: 1rem;
			overflow: hidden;
			@include bp(smd-max) {
				display: none;
			}
			> ul {
				> li {
					border-bottom: 0.1rem solid $darkGrey2;
					position: relative;
					background-color: $grey;
					@extend .transition;
					&:last-child {
						border-bottom: 0;
					}
					&.expanded {
						.arrow {
							@include rotate(180deg);
						}
					}
					&.active {
						background-color: $black1;
						> a {
							pointer-events: none;
						}
						.arrow {
							background-image: url("../images/down-arrow-white.svg");
							@include rotate(180deg);
							pointer-events: none;
						}
						> a {
							color: $white;
							.icon {
								.default-img {
									display: none;
								}
								.active-img {
									display: block;
								}
							}
						}
						.submenu {
							display: block;
						}
					}
					.arrow {
						position: absolute;
						right: 1.4rem;
						top: 2.1rem;
						width: 2.4rem;
						height: 2.4rem;
						z-index: 1;
						@extend .transition;
						background-image: url("../images/down-arrow.svg");
						background-size: 1.2rem;
						background-repeat: no-repeat;
						background-position: center;
						cursor: pointer;
						.rtl & {
							right: auto;
							left: 1.4rem;
						}
					}
					> a {
						display: flex;
						align-items: center;
						padding: 2rem;
						color: $black1;
						.icon {
							display: inline-flex;
							height: 2.4rem;
							width: 2.4rem;
							align-items: center;
							justify-content: center;
							.default-img {
								display: block;
							}
							.active-img {
								display: none;
							}
						}
						span {
							width: calc(100% - 2.4rem);
							padding: 0 1.2rem;
							margin-top: -0.4rem;
							@extend .truncated-text;
						}
					}
					.submenu {
						padding: 0 2.5rem 0 5.6rem;
						position: relative;
						top: -0.7rem;
						display: none;
						.rtl & {
							padding: 0 5.6rem 0 2.5rem;
						}
						li {
							margin-bottom: 1.6rem;
							&.active {
								a {
									color: $white;
								}
							}
							a {
								display: block;
								color: $darkGrey;
							}
						}
					}
				}
			}
		}
		.account-page-content {
			width: calc(100% - 29.4rem);
			padding-left: 3rem;
			.rtl & {
				padding-left: 0;
				padding-right: 3rem;
				@include bp(smd-max) {
					padding-right: 0;
				}
			}
			@include bp(smd-max) {
				width: 100%;
				padding-left: 0;
			}
			.system-msg {
				margin-bottom: 2.5rem;
			}
			.fixed-page-content {
				max-width: 72.6rem;
				margin: 0 auto;
				@include bp(smd-max) {
					margin: 0;
					max-width: inherit;
				}
			}
			.profile-btn {
				padding-top: 0.5rem;
				@include bp(sm-max) {
					padding-top: 0.3rem;
				}
				.orange-btn {
					width: 40.2rem;
					max-width: 100%;
					@include bp(xs-max) {
						width: 100%;
					}
				}
			}
			.delete-account {
				margin-top: 1.6rem;
				@include bp(sm-max) {
					margin-top: 1.4rem;
				}
			}
		}
	}
}

.account-delete-wrapper {
	.title {
		display: flex;
		margin-bottom: 1.6rem;
		@include bp(sm-max) {
			align-items: center;
			margin-bottom: 1.2rem;
		}
		.icon {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: $lightRed;
			width: 4.2rem;
			height: 4rem;
			border-radius: 50%;
			@include bp(sm-max) {
				width: 3rem;
				height: 3rem;
				position: relative;
				top: 0.2rem;
				img {
					width: 1.8rem;
				}
			}
		}
		h4 {
			width: calc(100% - 4.2rem);
			padding-left: 1.4rem;
			.rtl & {
				padding-left: 0;
				padding-right: 1.4rem;
			}
			@include bp(sm-max) {
				font-size: 1.6rem;
			}
		}
	}
	.deletion-info {
		color: $darkGrey;
		margin-bottom: 2rem;
	}
	.return-reason {
		margin-top: 1.6rem;
		width: 39rem;
		max-width: 100%;
		@include bp(sm-max) {
			margin-top: 1.3rem;
		}
	}
	.delete-btn {
		margin-top: 2rem;
		.orange-btn {
			width: 32.8rem;
			max-width: 100%;
		}
	}
}

.right-container {
	width: 83.5rem;
	max-width: 100%;
	margin-left: auto;
	@include bp(smd-max) {
		width: 100%;
	}
	.rtl & {
		margin-left: 0;
		margin-right: auto;
	}
}

.address-page-wrapper {
	.address-no-data {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 55rem;
		@include bp(lg-max) {
			min-height: 51rem;
		}
		@include bp(smd-max) {
			min-height: inherit;
			padding: 4.4rem 0;
		}
	}
	&.add-address-page {
		.map-block {
			.map-outer {
				position: relative;
				padding-bottom: 31.62%;
				margin-bottom: 0.8rem;
				@include bp(lg-max) {
					padding-bottom: 37%;
				}
				@include bp(sm-max) {
					padding-bottom: 50%;
				}
				@include bp(xs-max) {
					padding-bottom: 125%;
				}
				.map-frame {
					@extend .absolute-pos;
					border: 0;
				}
				.location-info {
					position: absolute;
					left: 50%;
					top: calc(50% + 0.8rem);
					@include translate(-50%, -50%);
					.location-pin {
						height: 1.4rem;
						width: 1.4rem;
						border-radius: 50%;
						background: $black1;
						position: relative;
						&::before {
							content: "";
							position: absolute;
							left: 50%;
							top: 50%;
							@include translate(-50%, -50%);
							height: 10rem;
							width: 10rem;
							background: rgba(228, 89, 53, 0.16);
							border-radius: 50%;
						}
					}
					.location-tooltip {
						position: absolute;
						width: 23.9rem;
						top: -6rem;
						left: 50%;
						@include translateX(-50%);
						background: $orange;
						box-shadow: 0 0.4rem 0.8rem rgba($black, 0.3);
						border-radius: 0.4rem;
						color: $white;
						text-align: center;
						padding: 0.7rem 0 1.1rem;
						font-size: 1.4rem;
						line-height: 2rem;
						&:after {
							content: "";
							position: absolute;
							bottom: -0.9rem;
							left: 50%;
							@include translateX(-50%);
							width: 0;
							height: 0;
							border-left: 0.6rem solid transparent;
							border-right: 0.6rem solid transparent;
							border-top: 0.9rem solid $orange;
						}
						h6 {
							font-size: 1.4rem;
							line-height: 2rem;
							margin: 0;
						}
					}
				}
			}
		}
		.address-info {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.address-left {
				width: calc(100% - 7rem);
				h5 {
					margin-bottom: 0.4rem;
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
				p {
					font-size: 1.4rem;
					line-height: 2rem;
				}
			}
			.address-change {
				width: 7rem;
				text-align: right;
				.rtl & {
					text-align: left;
				}
			}
		}
		.save-address-btn {
			margin-top: 3rem;
			.orange-btn {
				width: 100%;
			}
		}
	}
	&.all-addresses-page {
		width: 72.6rem;
		max-width: 100%;
		margin: 0 auto;
		.address-outer {
			border-bottom: 0.1rem solid $darkGrey2;
			padding-bottom: 2.3rem;
			margin-bottom: 1.7rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@include bp(sm-max) {
				padding-bottom: 2.1rem;
				margin-bottom: 1.8rem;
			}
			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
				margin-bottom: 0;
				@include bp(sm-max) {
					border-bottom: 0.1rem solid $darkGrey2;
					padding-bottom: 2.1rem;
				}
			}
			.address-info {
				max-width: 75%;
				width: 45rem;
				position: relative;
				@include bp(sm-max) {
					max-width: inherit;
					width: 100%;
				}
				h6 {
					margin-bottom: 0.4rem;
					@include bp(sm-max) {
						margin-bottom: 0.6rem;
						font-size: 1.4rem;
						line-height: 2.2rem;
					}
				}
				p {
					color: $darkGrey;
					margin: 0;
				}
				.default-tag {
					display: block;
					margin: 1.7rem 0 0;
					@include bp(sm-max) {
						margin: 0;
						position: absolute;
						right: 0;
						bottom: -3.1rem;
						z-index: 1;
						.rtl & {
							left: 0;
							right: auto;
						}
					}
				}
			}
			.action-links {
				@include bp(sm-max) {
					width: 100%;
					margin-top: 1.2rem;
				}
				ul {
					display: flex;
					align-items: center;
					li {
						margin-right: 2.1rem;
						.rtl & {
							margin-right: 0;
							margin-left: 2.1rem;
						}
						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
		.add-address-btn {
			display: flex;
			justify-content: center;
			margin-top: 4.4rem;
			.orange-btn {
				width: 40rem;
				max-width: 100%;
				@include bp(sm-max) {
					width: 100%;
				}
			}
		}
	}
}

.order-page {
	@include bp(smd-max) {
		margin: 0 -1.6rem;
	}
	&.is-order-detail {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;
		.order-detail-wrapper {
			padding: 0 1.5rem;
			width: 66.67%;
			@include bp(lg-max) {
				width: 100%;
			}
			@include bp(smd-max) {
				padding: 0;
			}
			.order-detail-outer {
				padding-bottom: 3.1rem;
				margin-bottom: 2.5rem;
				border-bottom: 0.1rem solid $darkGrey2;
				@include bp(smd-max) {
					padding: 0 1.6rem 3.1rem;
				}
				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
					border-bottom: 0;
				}
				.ordered-product-slider {
					@include bp(sm-max) {
						overflow: visible;
					}
				}
				h6 {
					font-size: 1.8rem;
					line-height: 2.6rem;
					margin-bottom: 1.6rem;
					@include bp(sm-max) {
						font-size: 1.6rem;
						line-height: 2.4rem;
						margin-bottom: 1.5rem;
					}
				}
				.product-card {
					.prod-content {
						.rtl & {
							padding-left: 0;
							padding-right: 3rem !important;
							@include bp(sm-max) {
								padding-right: 1.5rem !important;
							}
						}
						h5 {
							padding: 0;
						}
					}
				}
			}
			.customized-design-wrapper {
				.design-title {
					&:after {
						top: 0.5rem;
						@include bp(sm-max) {
							top: 0.2rem;
						}
					}
					h6 {
						margin: 0;
					}
				}
				.design-body {
					@include bp(sm-max) {
						padding: 0.5rem 0 0;
					}
				}
			}
			.order-status {
				.status-row {
					position: relative;
					padding-left: 2.3rem;
					margin-bottom: 1.2rem;
					@include bp(sm-max) {
						margin-bottom: 1.8rem;
					}
					.rtl & {
						padding-left: 0;
						padding-right: 2.3rem;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0.8rem;
						width: 1.6rem;
						height: 1.6rem;
						background-image: url("../images/tick-rounded.svg");
						background-size: 1.6rem;
						background-repeat: no-repeat;
						background-position: center;
						.rtl & {
							left: auto;
							right: 0;
						}
						@include bp(sm-max) {
							top: 0.3rem;
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
					.title {
						color: $orange;
						margin-bottom: 0.6rem;
						@include bp(sm-max) {
							margin-bottom: 0.3rem;
						}
					}
					.date-time {
						color: $black2;
					}
				}
			}
			.ordered-product-slider {
				.product-row {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -1.5rem -3rem;
					width: calc(100% + 3rem);
					@include bp(sm-max) {
						flex-wrap: nowrap;
						width: 100%;
						margin: 0;
					}
					.swiper-slide {
						width: 33.33%;
						padding: 0 1.5rem;
						margin-bottom: 3rem;
						@include bp(sm-max) {
							width: 15.4rem;
							padding: 0;
							margin-bottom: 0;
						}
						.product-card {
							.prod-thumb {
								padding-bottom: 100%;
							}
							.prod-content {
								padding: 0 !important;
								h5 {
									font-size: 1.6rem;
									line-height: 2.4rem;
									@include bp(sm-max) {
										font-size: 1.4rem;
										line-height: 2rem;
									}
								}
								.price {
									font-size: 1.6rem;
									line-height: 2.4rem;
									@include bp(smd-max) {
										font-size: 1.4rem;
										line-height: 2rem;
									}
									.discounted {
										@include bp(smd-max) {
											font-size: 1.6rem;
											line-height: 2.4rem;
										}
									}
									.discount {
										font-size: 1.4rem;
										line-height: 2rem;
										margin-left: 0.5rem;
										@include bp(smd-max) {
											font-size: 1.2rem;
											line-height: 1.8rem;
										}
										.rtl & {
											margin-left: 0;
											margin-right: 0.5rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.order-detail-summary {
			padding: 0 1.5rem;
			width: 33.33%;
			@include bp(lg-max) {
				width: 100%;
				margin-top: 3.3rem;
			}
			@include bp(smd-max) {
				padding: 0;
				border-top: 0.1rem solid $darkGrey2;
			}
			.cart-summary-wrapper {
				.inner-cart-block {
					.summary-title {
						h6 {
							font-size: 1.8rem;
							line-height: 2.6rem;
							@include bp(sm-max) {
								font-size: 1.6rem;
								line-height: 2.4rem;
							}
						}
					}
					.price-row {
						.label {
							width: 50%;
							font-family: $regularFont;
							@include bp(sm-max) {
								font-size: 1.4rem;
								line-height: 2rem;
							}
						}
						.price {
							width: 50%;
							text-align: right;
							.rtl & {
								text-align: left;
							}
						}
					}
				}
			}
		}
	}
	.order-row {
		padding-bottom: 3rem;
		margin-bottom: 3rem;
		border-bottom: 0.1rem solid $darkGrey2;
		@include bp(sm-max) {
			padding: 0 1.6rem 2.2rem;
			margin-bottom: 2rem;
		}
		&:last-child {
			margin: 0;
		}
	}
}

.policies-page {
	h5 {
		margin-bottom: 1.2rem;
		@include bp(sm-max) {
			font-size: 1.6rem;
			line-height: 2.2rem;
		}
	}
	p {
		color: $darkGrey;
	}
}
