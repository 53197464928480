.progress-steps {
	ul {
		position: relative;
		display: flex;
		z-index: 0;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: calc(50% - 0.3rem);
			height: 0.6rem;
			background-color: rgba($darkGrey, 0.14);
			border-radius: 3rem;
		}
		li {
			flex: 1;
			position: relative;
			&.completed {
				&::before {
					border-color: $orange;
					background-color: $orange;
					background-image: url("../images/tick.svg");
					background-size: 1.6rem;
					background-repeat: no-repeat;
					background-position: center;
				}
				&::after {
					width: 100%;
				}
			}
			&:last-child {
				flex: inherit;
				&::after {
					display: none;
				}
			}
			&::before {
				content: "";
				position: relative;
				z-index: 1;
				height: 3rem;
				width: 3rem;
				background: $white;
				border: 0.1rem solid $darkGrey;
				border-radius: 50%;
				display: block;
				@extend .transition;
			}
			&:after {
				content: "";
				position: absolute;
				left: 1.5rem;
				top: calc(50% - 0.3rem);
				background: $orange;
				height: 0.6rem;
				width: 0;
				z-index: -1;
				@extend .transition;
				.rtl & {
					left: auto;
					right: 0;
				}
			}
		}
	}
}

.merchant-reg-page {
	padding: 8.4rem 0 12rem;
	@include bp(lg-max) {
		padding: 6rem 0 10rem;
	}
	@include bp(smd-max) {
		padding: 5rem 0 9rem;
	}
	@include bp(sm-max) {
		padding: 3rem 0 7.9rem;
	}
	.reg-wrapper {
		margin-top: 4.3rem;
		@include bp(smd-max) {
			margin-top: 3.5rem;
		}
		@include bp(sm-max) {
			margin-top: 2.5rem;
		}
		h2 {
			margin-bottom: 4.2rem;
			@include bp(smd-max) {
				margin-bottom: 3rem;
				font-size: 2.6rem;
				line-height: 3.4rem;
			}
			@include bp(sm-max) {
				font-size: 2rem;
				line-height: 2.8rem;
			}
		}
		.reg-form {
			.form-group {
				> label {
					@include bp(sm-max) {
						margin-bottom: 0.9rem;
					}
				}
			}
			.reg-btns {
				display: flex;
				align-items: center;
				margin-top: 0.5rem;
				@include bp(sm-max) {
					justify-content: space-between;
				}
				&.single-btn {
					.orange-btn {
						@include bp(sm-max) {
							width: 100%;
						}
					}
				}
				.orange-btn {
					min-width: 16.4rem;
					margin-right: 1.2rem;
					&.lg {
						min-width: 27.7rem;
						padding: 0;
					}
					@include bp(sm-max) {
						min-width: inherit !important;
						width: calc(50% - 1rem);
					}
					&:first-child {
						.rtl & {
							margin-right: 0;
						}
					}
					&:last-child {
						margin-right: 0;
						.rtl & {
							margin-right: 1.2rem;
						}
					}
					&.is-bordered {
						min-width: 19.8rem;
						@include bp(sm-max) {
							min-width: inherit;
						}
					}
				}
			}
		}
	}
}

.merchant-pages {
	padding: 2.6rem 0 13rem;
	@include bp(smd-max) {
		padding: 2.6rem 0 10rem;
	}
	@include bp(sm-max) {
		padding: 2rem 0 9.2rem;
	}
	.merchant-wrapper {
		margin-top: 4.5rem;
		@include bp(sm-max) {
			margin-top: 3.5rem;
		}
		@include bp(sm-max) {
			margin-top: 2.5rem;
		}
		.merchant-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 4.7rem;
			@include bp(lg-max) {
				margin-bottom: 3.5rem;
			}
			@include bp(sm-max) {
				margin-bottom: 2rem;
			}
			h2 {
				margin: 0;
				@include bp(lg-max) {
					font-size: 2.6rem;
				}
				@include bp(smd-max) {
					font-size: 2.4rem;
				}
				@include bp(sm-max) {
					font-size: 2rem;
					line-height: 2.8rem;
				}
			}
			.primary-link {
				position: relative;
				top: 0.4rem;
				@include bp(sm-max) {
					top: 0rem;
				}
			}
		}
	}
}

.merchant-listing-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem -3rem;
	@include bp(sm-max) {
		margin-bottom: -2rem;
	}
	.merchant-col {
		width: 33.33%;
		padding: 0 1.5rem;
		margin-bottom: 3rem;
		@include bp(lg-max) {
			width: 50%;
		}
		@include bp(sm-max) {
			width: 100%;
			margin-bottom: 2rem;
		}
	}
}

.merchant-card {
	background: $grey;
	padding: 3rem 2.8rem;
	display: flex;
	flex-wrap: wrap;
	border-radius: 1rem;
	position: relative;
	@include bp(lg-max) {
		padding: 2.5rem;
	}
	@include bp(smd-max) {
		padding: 2rem;
	}
	@include bp(sm-max) {
		padding: 1.2rem;
	}
	&.is-transparent {
		padding: 0;
		border-radius: 0;
		background-color: transparent;
		.merchant-btns {
			.orange-btn {
				&.is-white {
					background-color: $grey;
				}
			}
		}
	}
	&.has-right-link {
		padding-right: 4rem;
		@include bp(sm-max) {
			padding-right: 3.6rem;
		}
		.rtl & {
			padding-right: 0;
			padding-left: 4rem;
			@include bp(sm-max) {
				padding-left: 3.6rem;
			}
		}
	}
	.arrow-link {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 2rem;
		width: 2rem;
		position: absolute;
		right: 0;
		top: 50%;
		@include translateY(-50%);
		.rtl & {
			right: auto;
			left: 0;
		}
		img {
			@include rotate(-90deg);
			display: block;
			line-height: 0;
			width: 1.6rem;
			.rtl & {
				@include rotate(90deg);
			}
		}
	}
	.logo {
		width: 8rem;
		height: 8rem;
		display: inline-block;
		border-radius: 50%;
		overflow: hidden;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
	.details {
		width: calc(100% - 8rem);
		padding-left: 2rem;
		margin-top: -0.4rem;
		.rtl & {
			padding-left: 0;
			padding-right: 2rem;
		}
		@include bp(smd-max) {
			margin-top: -0.3rem;
			padding-left: 1.4rem;
			.rtl & {
				padding-left: 0;
				padding-right: 1.4rem;
			}
		}
		h6 {
			font-size: 1.8rem;
			line-height: 2.6rem;
			margin-bottom: 1.4rem;
			@extend .truncated-text;
			color: $black1;
			@include bp(smd-max) {
				margin-bottom: 1rem;
			}
			@include bp(sm-max) {
				font-size: 1.6rem;
				line-height: 2.4rem;
			}
		}
		.merchant-btns {
			display: flex;
			justify-content: space-between;
			.orange-btn {
				width: calc(100% - 5.4rem);
				font-size: 1.4rem;
				height: 4rem;
				padding: 0;
				@include bp(sm-max) {
					width: calc(100% - 6rem);
				}
				&.is-white {
					width: 4.4rem;
				}
			}
		}
	}
}

.merchant-details {
	display: flex;
	margin: 0 -1.5rem;
	flex-wrap: wrap;
	.merchant-basic-info {
		width: 33.3%;
		padding: 0 1.5rem;
		@include bp(smd-max) {
			width: 100%;
		}
		.info-wrapper {
			border-radius: 1rem;
			padding: 3rem;
			background-color: $grey;
			text-align: center;
			@include bp(sm-max) {
				padding: 2.4rem 1.6rem;
			}
			h6 {
				font-size: 1.8rem;
				line-height: 2.6rem;
				margin-bottom: 1.4rem;
				@include bp(sm-max) {
					margin-bottom: 1.6rem;
				}
			}
			.logo {
				width: 8rem;
				height: 8rem;
				display: inline-block;
				border-radius: 50%;
				overflow: hidden;
				margin-bottom: 1.2rem;
				img {
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
			h5 {
				margin-bottom: 1rem;
			}
			.orange-btn {
				width: 23.3rem;
				max-width: 100%;
				margin: 0 auto;
				@include bp(sm-max) {
					width: 30rem;
				}
			}
			.notes {
				margin-top: 1.6rem;
				font-size: 1.4rem;
				line-height: 2rem;
				color: $black2;
				@include bp(xs-max) {
					text-align: left;
				}
				p {
					margin-bottom: 1.3rem;
					&.secondary {
						font-size: 1.2rem;
						line-height: 1.8rem;
						font-family: $regularFont;
						@include bp(sm-max) {
							font-size: 1rem;
							line-height: 1.5rem;
						}
					}
					strong {
						font-family: $mediumFont;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.merchant-branches {
		width: 66.67%;
		padding: 0 1.5rem;
		@include bp(smd-max) {
			width: 100%;
			margin-top: 2rem;
		}
		.branches-wrapper {
			border-radius: 1rem;
			padding: 2.7rem 3rem;
			background-color: $grey;
			@include bp(smd-max) {
				margin: 0 -1.6rem;
				padding: 2.5rem 1.6rem;
				border-radius: 0;
			}
			h5 {
				margin-bottom: 2.1rem;
				font-size: 2rem;
				line-height: 2.8rem;
			}
			.branch-outer {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				padding-bottom: 3rem;
				margin-bottom: 2.4rem;
				border-bottom: 0.1rem solid $lightGrey;
				@include bp(sm-max) {
					padding-bottom: 2.3rem;
					margin-bottom: 2rem;
				}
				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
					border-bottom: 0;
				}
				.branch-info {
					width: calc(100% - 35.8rem);
					padding-right: 1.5rem;
					.rtl & {
						padding-right: 0;
						padding-left: 1.5rem;
						@include bp(lg-max) {
							padding-left: 0;
						}
					}
					@include bp(xlg-max) {
						width: calc(100% - 32.8rem);
					}
					@include bp(lg-max) {
						padding-right: 0;
						width: 100%;
					}
					p {
						font-size: 1.4rem;
						line-height: 2.2rem;
						font-family: $mediumFont;
						margin-bottom: 0.3rem;
						@include bp(smd-max) {
							margin-bottom: 0.6rem;
						}
					}
					.primary-link {
						font-size: 1.4rem;
						line-height: 2rem;
					}
				}
				.branch-btns {
					width: 32.8rem;
					max-width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding-top: 0.4rem;
					padding: 0;
					@include bp(lg-max) {
						margin-top: 1.6rem;
					}
					@include bp(sm-max) {
						margin-top: 2rem;
					}
					.orange-btn {
						width: calc(50% - 1rem);
						@include bp(xxs-max) {
							width: calc(50% - 0.2rem);
						}
					}
				}
			}
		}
	}
}
