h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0 0 2rem;
	padding: 0;
	font-weight: normal;
	&:last-child {
		margin-bottom: 0;
	}
}

h1,
.h1 {
	font-size: 4.8rem;
	line-height: 5.6rem;
	font-family: $mediumFont;
	@include bp(lg-max) {
		font-size: 4rem;
		line-height: 5.2rem;
	}
	@include bp(smd-max) {
		font-size: 3.4rem;
		line-height: 4.4rem;
	}
	@include bp(sm-max) {
		font-size: 2rem;
		line-height: 2.8rem;
	}
}

h2,
.h2 {
	font-size: 3rem;
	line-height: 4rem;
	font-family: $mediumFont;
	@include bp(smd-max) {
		font-size: 2.8rem;
		line-height: 3.6rem;
	}
	@include bp(sm-max) {
		font-size: 2.6rem;
		line-height: 3.4rem;
	}
}

h3,
.h3 {
	font-size: 2.6rem;
	line-height: 3.4rem;
	font-family: $mediumFont;
	@include bp(smd-max) {
		font-size: 2.4rem;
		line-height: 3rem;
	}
	@include bp(sm-max) {
		font-size: 2.2rem;
		line-height: 2.8rem;
	}
}

h4,
.h4 {
	font-size: 2.2rem;
	line-height: 3rem;
	font-family: $mediumFont;
	@include bp(lg-max) {
		font-size: 2rem;
		line-height: 2.6rem;
	}
	@include bp(sm-max) {
		font-size: 1.8rem;
		line-height: 2.3rem;
	}
}

h5,
.h5 {
	font-size: 2rem;
	line-height: 2.8rem;
	font-family: $mediumFont;
	@include bp(lg-max) {
		font-size: 1.8rem;
		line-height: 2.4rem;
	}
	@include bp(smd-max) {
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	@include bp(sm-max) {
		font-size: 1.4rem;
		line-height: 2rem;
	}
}

h6,
.h6 {
	font-size: 1.6rem;
	line-height: 2.3rem;
	font-family: $mediumFont;
}

strong {
	font-weight: normal;
	font-family: $mediumFont;
}

.cms-content {
	p {
		margin-bottom: 1.6rem;
		@include bp(sm-max) {
			line-height: 2.2rem;
			margin-bottom: 1.2rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		color: $orange;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	ul {
		margin-bottom: 4rem;
		margin-top: 2.2rem;
		@include bp(lg-max) {
			margin-top: 2rem;
			margin-bottom: 3rem;
		}
		@include bp(sm-max) {
			margin-top: 1.5rem;
			margin-bottom: 2rem;
		}
		li {
			position: relative;
			padding-left: 2.7rem;
			line-height: 1.4;
			.rtl & {
				padding-left: 0;
				padding-right: 2.7rem;
			}
			@include bp(sm-max) {
				line-height: 1.3;
				padding-left: 2rem;
				.rtl & {
					padding-right: 2rem;
				}
			}
			+ li {
				margin-top: 0.2rem;
			}
			&::before {
				position: absolute;
				content: "";
				left: 1rem;
				top: 1.1rem;
				height: 0.4rem;
				width: 0.4rem;
				border-radius: 50%;
				background-color: $black1;
				.rtl & {
					left: auto;
					right: 1rem;
				}
				@include bp(sm-max) {
					left: 0;
					.rtl & {
						right: 0;
					}
				}
			}
			ol {
				margin-top: 1rem;
				margin-bottom: 1rem;
				li {
					padding-left: 0;
					.rtl & {
						padding-right: 0;
					}
					&::before {
						display: none;
					}
				}
			}
			ul {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}
	}
	ol {
		margin-bottom: 4rem;
		padding-left: 2.7rem;
		margin-top: 2.2rem;
		list-style-type: number;
		.rtl & {
			padding-left: 0;
			padding-right: 2.7rem;
		}
		@include bp(lg-max) {
			margin-top: 2rem;
			margin-bottom: 3rem;
		}
		@include bp(sm-max) {
			margin-top: 1.5rem;
			margin-bottom: 2rem;
			padding-left: 2rem;
			.rtl & {
				padding-right: 2rem;
			}
		}
		li {
			+ li {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}
		ul,
		ol {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	}
	blockquote {
		padding: 2.5rem 3rem 3rem;
		border-left: 0.5rem solid $orange;
		margin: 2rem 0;
		background-color: rgba($orange, 0.05);
		.rtl & {
			border-left: 0;
			border-right: 0.5rem solid $orange;
			padding: 3rem 3rem 2.5rem;
		}
		@include bp(lg-max) {
			padding: 2rem 2.5rem 2.5rem;
			.rtl & {
				padding: 2.5rem 2.5rem 2rem;
			}
		}
		@include bp(sm-max) {
			padding: 1rem 1.5rem 1.5rem;
			.rtl & {
				padding: 1.5rem 1.5rem 1rem;
			}
		}
	}
}
