.modal {
	.modal-close {
		cursor: pointer;
		display: inline-flex;
		position: absolute;
		right: -3.5rem;
		top: 0;
		.rtl & {
			left: -3.5rem;
			right: auto;
		}
		@include bp(sm-max) {
			right: auto !important;
			left: 50% !important;
			transform: translateX(-50%);
			width: 1.8rem;
			top: -3.3rem;
		}
	}
	.modal-content {
		background-color: $white;
		border-radius: 1.5rem;
		box-shadow: 0 0.3rem 0.6rem 0.3rem rgba($black, 0.1);
		@include bp(sm-max) {
			border-radius: 3rem 3rem 0 0;
		}
	}
	.modal-dialog {
		max-width: 46rem;
		@include bp(sm-max) {
			align-items: flex-end;
			margin: 0;
			min-height: 100%;
			max-width: inherit;
			width: 100%;
			padding-top: 5rem;
		}
		&.msm-modal {
			max-width: 49rem;
			@include bp(sm-max) {
				max-width: inherit;
			}
		}
		&.bottom-aligned {
			align-items: flex-end;
			margin: 0;
			min-height: 100%;
			max-width: inherit;
			width: 100%;
			.modal-content {
				border-radius: 3rem 3rem 0 0;
			}
			.modal-close {
				right: auto !important;
				left: 50% !important;
				transform: translateX(-50%);
				width: 1.8rem;
				top: -3.3rem;
			}
			.modal-body {
				padding: 0 1.6rem 2.7rem;
				&.has-btns {
					padding-bottom: 9.4rem;
				}
				h2 {
					margin: 0 -1.6rem 1.1rem;
					text-align: inherit;
					font-size: 1.6rem;
					line-height: 2.2rem;
					background: $grey;
					border-radius: 3rem 3rem 0 0;
					padding: 1.5rem 1.6rem 1.9rem;
				}
				.modal-btn-block {
					padding: 1.5rem 1.6rem;
					background-color: $white;
					margin: 0 -1.6rem -3rem;
					box-shadow:
						0 -2rem 2.4rem -0.4rem rgba($black, 0.07),
						0 -0.8rem 0.8rem -0.4rem rgba($black, 0.04);
					margin: 0 !important;
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
				}
			}
		}
	}
	.modal-body {
		padding: 5rem 5rem;
		@include bp(sm-max) {
			padding: 3rem 1.6rem;
		}
		&.has-modal-title {
			padding: 0 0 3rem;
			.modal-title {
				padding: 1.2rem 1rem 1.7rem;
				background: $grey;
				border-radius: 3rem 3rem 0 0;
				text-align: center;
				font-size: 2rem;
				line-height: 3rem;
				font-family: $mediumFont;
				@include bp(sm-max) {
					font-size: 1.6rem;
					line-height: 2.4rem;
					text-align: left;
					padding: 1.3rem 1.6rem 2rem;
					.rtl & {
						text-align: right;
					}
				}
			}
			.modal-body-content {
				padding: 2.7rem 5rem;
				@include bp(sm-max) {
					padding: 1.6rem 1.6rem 0;
				}
				.mCSB_container {
					padding: 0 5rem;
					@include bp(sm-max) {
						padding: 0 1.6rem;
					}
				}
			}
			.modal-btn-block {
				padding: 0 5rem;
				margin-top: 0.7rem;
				@include bp(sm-max) {
					padding: 1.5rem 1.6rem;
				}
			}
		}
		&.secondary {
			padding: 3rem;
			@include bp(sm-max) {
				padding: 3rem 1.6rem;
			}
		}
		&.sm-pad {
			padding: 5rem 3rem;
			@include bp(sm-max) {
				padding: 3rem 1.6rem;
			}
		}
		&.has-btns {
			@include bp(sm-max) {
				padding-bottom: 10rem;
			}
		}
		&.clear-filter-body {
			.checkbox-list {
				.custom-secondary-checkbox {
					margin-bottom: 1.6rem;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		.bottom-link {
			display: flex;
			justify-content: center;
			margin-top: 1.5rem;
			font-size: 1.8rem;
			line-height: 2.6rem;
			@include bp(sm-max) {
				font-size: 1.4rem;
				line-height: 2.2rem;
				margin-top: 1.8rem;
			}
		}
		.modal-btn-block {
			display: flex;
			justify-content: space-between;
			&.full-width {
				> * {
					width: 100%;
				}
			}
			&.mob-static {
				@include bp(sm-max) {
					padding: 0;
					background-color: transparent;
					box-shadow: none;
					margin: 2.3rem 0 0 !important;
					position: static;
				}
			}
			> * {
				width: calc(50% - 0.7rem);
				@include bp(sm-max) {
					width: calc(50% - 1rem);
				}
			}
			@include bp(sm-max) {
				padding: 1.5rem 1.6rem;
				background-color: $white;
				margin: 0 -1.6rem -3rem;
				box-shadow:
					0 -2rem 2.4rem -0.4rem rgba($black, 0.07),
					0 -0.8rem 0.8rem -0.4rem rgba($black, 0.04);
				margin: 0 !important;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
		&.email-change {
			min-height: 43rem;
			display: flex;
			align-items: center;
			justify-content: center;
			@include bp(sm-max) {
				min-height: inherit;
				padding-top: 0;
			}
			.body-content {
				width: 100%;
				.email-btn {
					margin-top: -0.5rem;
					&.has-margin {
						margin-top: 1.5rem;
					}
					.orange-btn {
						width: 100%;
					}
				}
				h2 {
					font-family: $semiboldFont;
					text-align: center;
					@include bp(sm-max) {
						margin: 0 -1.6rem 2.4rem;
						text-align: inherit;
						font-size: 1.6rem;
						line-height: 2.2rem;
						background: $grey;
						border-radius: 3rem 3rem 0 0;
						padding: 1.5rem 1.6rem 1.9rem;
					}
				}
				.otp-wrapper {
					padding-top: 0.5rem;
					@include bp(sm-max) {
						padding-top: 0.9rem;
					}
				}
				.resend-block {
					text-align: center;
					margin-top: 3.6rem;
					@include bp(sm-max) {
						margin-top: 2.7rem;
					}
				}
			}
			h2 {
				margin-bottom: 3.1rem;
			}
		}
		&.account-delete {
			&.is-success {
				padding: 6rem 5rem;
				@include bp(sm-max) {
					padding: 3rem 1.6rem;
				}
				.body-content {
					max-width: 36rem;
					margin: 0 auto;
					@include bp(sm-max) {
						max-width: inherit;
					}
					.title-block {
						margin: 0;
						h4 {
							&.fixed-width {
								@include bp(sm-max) {
									max-width: inherit;
								}
							}
						}
						p {
							margin-top: 0.8rem;
						}
					}
				}
			}
			&.payment-status-modal {
				.title-block {
					.icon {
						margin-bottom: 2.5rem;
						@include bp(sm-max) {
							margin-bottom: 4.6rem;
						}
					}
				}
			}
			.title-block {
				text-align: center;
				margin-bottom: 2.9rem;
				.icon {
					display: inline-flex;
					margin-bottom: 1.5rem;
				}
				h4 {
					margin-bottom: 0.7rem;
					&:last-child {
						margin: 0;
					}
					&.fixed-width {
						max-width: 27rem;
						margin: 0 auto;
					}
				}
			}
			.form-group {
				margin-bottom: 3rem;
				@include bp(sm-max) {
					margin-bottom: 0;
				}
			}
		}
		.sort-options {
			li {
				margin-bottom: 2.3rem;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					font-size: 1.4rem;
					line-height: 2rem;
					display: block;
					position: relative;
					padding-right: 3rem;
					@extend .truncated-text;
					.rtl & {
						padding-right: 0;
						padding-left: 3rem;
					}
					&::after {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						height: 2.4rem;
						width: 2.4rem;
						background-image: url("../images/tick-orange.svg");
						background-position: center;
						background-repeat: no-repeat;
						background-size: 1.7rem;
						display: none;
						.rtl & {
							right: auto;
							left: 0;
						}
					}
					&.selected {
						font-family: $mediumFont;
						&::after {
							display: block;
						}
					}
				}
			}
		}
		.modal-addresses {
			max-height: 38.6rem;
			overflow: auto;
			@include bp(sm-max) {
				max-height: 35.8rem;
			}
			.address-outer {
				position: relative;
				border-bottom: 0.1rem solid $darkGrey2;
				padding-bottom: 2.1rem;
				margin-bottom: 1.7rem;
				&:last-child {
					border-bottom: 0;
					margin-bottom: 0;
					padding-bottom: 0;
				}
				.radio-outer {
					margin: 0;
					position: absolute;
					right: -0.1rem;
					top: 0.3rem;
					.rtl & {
						right: auto;
						left: -0.1rem;
					}
				}
				h6 {
					font-size: 1.6rem;
					line-height: 2.4rem;
					margin-bottom: 0.3rem;
					padding-right: 3rem;
					.rtl & {
						padding-right: 0;
						padding-left: 3rem;
					}
					@include bp(sm-max) {
						font-size: 1.4rem;
						line-height: 2.2rem;
						margin-bottom: 0.4rem;
					}
				}
				.address {
					color: $darkGrey;
					margin-bottom: 1.1rem;
					@include bp(sm-max) {
						line-height: 2.2rem;
					}
				}
				.links-block {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.default {
						font-family: $mediumFont;
					}
					.address-links {
						display: flex;
						li {
							margin-right: 2rem;
							.rtl & {
								margin-right: 0;
								margin-left: 2rem;
							}
							&:last-child {
								margin: 0;
							}
							a {
								&.orange-link {
									font-family: $mediumFont;
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
		.modal-gemstone-detail {
			max-height: 46rem;
			overflow: auto;
			@include bp(sm-max) {
				max-height: 40rem;
			}
			.gemstone-detail-wrapper {
				border-bottom: 0.1rem solid $darkGrey2;
				padding-bottom: 1.7rem;
				margin-bottom: 0.9rem;
				&:last-child {
					border-bottom: 0;
					padding-bottom: 0;
					margin-bottom: 0;
				}
				h6 {
					margin-bottom: 1.2rem;
					color: $orange;
				}
				.detail-row {
					display: flex;
					justify-content: space-between;
					margin-bottom: 1.4rem;
					&:last-child {
						margin-bottom: 0;
					}
					.title {
						color: $darkGrey;
					}
				}
			}
		}
		.review-block {
			.ratings {
				display: flex;
				line-height: 0;
				justify-content: center;
				margin-bottom: 3rem;
				span {
					margin-right: 2.4rem;
					.rtl & {
						margin-right: 0;
						margin-left: 2.4rem;
					}
					&:last-child {
						margin: 0;
					}
					img {
						width: 2.6rem;
					}
				}
			}
		}
		.radio-wrapper {
			margin-top: 2.4rem;
			@include bp(sm-max) {
				margin-top: 1.7rem;
			}
			h6 {
				margin-bottom: 1.5rem;
				font-size: 1.8rem;
				line-height: 2.4rem;
				@include bp(sm-max) {
					font-size: 1.6rem;
					line-height: 2rem;
				}
			}
		}
		.return-reason {
			margin-top: 1.6rem;
			@include bp(sm-max) {
				margin-top: 1.3rem;
			}
			textarea {
				&.form-control {
					@include bp(sm-max) {
						min-height: 12rem;
					}
				}
			}
		}
		.caution-note {
			font-size: 1.4rem;
			line-height: 2.2rem;
			color: $red;
			margin-top: 0.9rem;
			@include bp(sm-max) {
				font-size: 1.2rem;
				line-height: 1.8rem;
				margin-top: 1.7rem;
			}
		}
	}
}
