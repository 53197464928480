.transition {
	@include transition(all 0.3s ease-in);
}

.bg-props {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.absolute-pos {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
}

.cover-fit {
	object-fit: cover;
	object-position: center;
	-o-object-position: center;
}

.no-bullets {
	list-style: none;
	padding: 0;
	margin: 0;
}

.truncated-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.hidden-input {
	@extend .absolute-pos;
	opacity: 0;
	cursor: pointer;
	z-index: 1;
	&::-webkit-file-upload-button {
		cursor: pointer;
	}
}
