.site-footer {
	background-color: $black1;
	padding: 9.7rem 0 2rem;
	color: $white;
	font-size: 1.6rem;
	line-height: 2.3rem;
	@include bp(sm-max) {
		padding: 7.8rem 0 2.5rem;
	}
	.top-footer {
		display: flex;
		flex-wrap: wrap;
		.logo-block {
			width: 23.1%;
			@include bp(lg-max) {
				width: 100%;
				margin-bottom: 4rem;
				text-align: center;
			}
			@include bp(sm-max) {
				margin-bottom: 3.5rem;
			}
			.footer-logo {
				margin-bottom: 3.6rem;
			}
			.social-links {
				display: flex;
				flex-wrap: wrap;
				@include bp(lg-max) {
					justify-content: center;
				}
				li {
					margin-right: 1.5rem;
					.rtl & {
						margin-right: 0;
						margin-left: 1.5rem;
					}
					&:last-child {
						margin: 0;
					}
					a {
						display: inline-flex;
						align-items: center;
						justify-content: center;
						height: 2.6rem;
						width: 2.6rem;
						border-radius: 0.4rem;
						background: $white;
						&:hover {
							opacity: 0.8;
						}
					}
				}
			}
		}
		.footer-links {
			display: flex;
			flex-wrap: wrap;
			@include bp(lg-max) {
				width: 100%;
				justify-content: space-between;
			}
			.link-col {
				padding: 0 3.1rem;
				max-width: 32rem;
				@include bp(lg-max) {
					width: 33.33%;
				}
				@include bp(sm-max) {
					width: 47%;
					padding: 0;
				}
				&:first-child {
					@include bp(lg-max) {
						padding-left: 0;
					}
					@include bp(sm-max) {
						width: 53%;
					}
				}
				&:last-child {
					@include bp(lg-max) {
						padding-right: 0;
					}
					@include bp(sm-max) {
						width: 100%;
						margin-top: 4rem;
					}
				}
				h6 {
					font-family: $regularFont;
					color: $darkGrey;
					margin-bottom: 1.5rem;
				}
				ul {
					li {
						margin-bottom: 1.4rem;
						&:last-child {
							margin: 0;
						}
						a {
							color: $white;
							&:hover {
								color: $orange;
							}
						}
					}
				}
			}
		}
		.app-btns {
			margin-left: auto;
			.rtl & {
				margin-left: 0;
				margin-right: auto;
			}
			@include bp(lg-max) {
				margin-left: 0;
				width: 100%;
				margin-top: 4rem;
				text-align: center;
			}
			@include bp(sm-max) {
				margin-top: 3.8rem;
			}
			h6 {
				font-family: $regularFont;
				margin-bottom: 2.1rem;
				@include bp(sm-max) {
					margin-bottom: 2.3rem;
				}
			}
			ul {
				@include bp(lg-max) {
					display: flex;
					justify-content: center;
				}
				li {
					margin-bottom: 1.8rem;
					@include bp(lg-max) {
						margin-bottom: 0;
						margin-right: 2rem;
						.rtl & {
							margin-right: 0;
							margin-left: 2rem;
						}
					}
					&:last-child {
						margin: 0;
					}
					a {
						display: flex;
					}
				}
			}
		}
	}
	.bottom-footer {
		margin-top: 6rem;
		color: $darkGrey1;
		@include bp(sm-max) {
			margin-top: 3.8rem;
			font-size: 1.4rem;
			line-height: 2rem;
		}
		p {
			display: flex;
			align-items: center;
			justify-content: center;
			.icon {
				line-height: 0;
				margin-right: 0.8rem;
				position: relative;
				top: 0.3rem;
				.rtl & {
					margin-right: 0;
					margin-left: 0.8rem;
				}
			}
		}
	}
}
