::-moz-selection {
	color: $white;
	background: $black1;
}

::selection {
	color: $white;
	background: $black1;
}

html {
	font-size: 62.5%;
	min-height: 100%;
}

body {
	font-size: 1.6rem;
	line-height: 2.4rem;
	min-width: 32rem;
	overflow-x: hidden;
	background: $white;
	color: $black1;
	min-height: 100%;
	font-family: $regularFont;
	font-weight: 400;
	-webkit-font-smoothing: subpixel-antialiased;
	@include bp(sm-max) {
		font-size: 1.4rem;
		line-height: 2rem;
	}
	&.sidebar-open {
		overflow: hidden !important;
		&::before {
			content: "";
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			overflow: hidden;
			background-color: rgba($black, 0.5);
			z-index: 990;
		}
	}
}

.menu-open {
	@include bp(smd-max) {
		overflow: hidden !important;
	}
}

.mob-filter-open {
	@include bp(md-max) {
		overflow: hidden !important;
	}
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

*:focus {
	outline: none !important;
}

a {
	display: inline-block;
	@include transition(all 0.3s);
	text-decoration: none;
	color: $black1;
	&:hover {
		color: $orange;
		text-decoration: none;
	}
}

.container {
	max-width: 129.6rem;
	--bs-gutter-x: 3rem;
	@include bp(lg-max) {
		--bs-gutter-x: 3.2rem;
	}
	&.sm {
		max-width: 108rem;
	}
}

.row {
	--bs-gutter-x: 3rem;
}

img {
	max-width: 100%;
}

::-ms-clear {
	display: none;
}

input[type="text"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
input[type="text"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

.wrapper {
	min-height: 100vh;
	padding-top: 12.3rem;
}

.mCSB_inside {
	> .mCSB_container {
		margin-right: 0;
		.rtl & {
			margin-left: 0;
		}
	}
	.mCSB_scrollTools {
		width: 0.4rem;
		opacity: 1;
		.mCSB_dragger_bar {
			background: $black1 !important;
			border-radius: 10rem;
			opacity: 1;
		}
		.mCSB_draggerRail {
			background-color: transparent;
		}
	}
}

.rtl {
	direction: rtl;
}

.mob-mb0 {
	@include bp(sm-max) {
		margin-bottom: 0 !important;
	}
}

.mob-sticky-btn {
	@include bp(sm-max) {
		position: fixed;
		bottom: 0;
		z-index: 99;
		background: $white;
		left: 0;
		right: 0;
		padding: 1.5rem 1.6rem !important;
		box-shadow: 0 -2rem 2.4rem -0.4rem rgba($black, 0.07), 0 -0.8rem 0.8rem -0.4rem rgba($black, 0.04);
	}
}
