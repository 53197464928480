.site-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 5;
	background-color: $white;
	box-shadow: 0 0 2.4rem rgba($black, 0.1);
	@include bp(sm-max) {
		box-shadow: 0 0.4rem 1rem rgba($black, 0.1);
	}
	.top-header {
		.container {
			height: 7.8rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include bp(smd-max) {
				flex-wrap: wrap;
				height: 12.2rem;
				align-items: flex-start;
				padding: 2.1rem 1.6rem 1.8rem;
			}
		}
		.left-header {
			display: flex;
			align-items: center;
			.site-logo {
				line-height: 0;
				@include bp(smd-max) {
					width: 16rem;
				}
			}
		}
		.right-header {
			display: flex;
			align-items: center;
			@include bp(smd-max) {
				width: 100%;
				margin-top: 1.8rem;
			}
			> * {
				margin-right: 3rem;
				@include bp(lg-max) {
					margin-right: 2rem;
				}
				@include bp(smd-max) {
					margin-right: 1.6rem;
				}
				.rtl & {
					&:first-child {
						margin-right: 0;
					}
				}
				&:last-child {
					margin-right: 0;
					.rtl & {
						margin-right: 3rem;
						@include bp(lg-max) {
							margin-right: 2rem;
						}
						@include bp(smd-max) {
							margin-right: 1.6rem;
						}
					}
				}
			}

			.unread-bubble {
				display: inline-block;
				width: 1.2rem;
				height: 1.2rem;
				position: absolute;
				right: 0;
				top: 0;
				border-radius: 50%;
				background-color: $orange;
				z-index: 1;
				@include bp(smd-max) {
					height: 1rem;
					width: 1rem;
				}
			}

			.cart {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				position: relative;
				@extend .transition;
				@include bp(smd-max) {
					margin: 0;
					width: 2.4rem;
					.rtl & {
						margin-right: 1.6rem;
					}
				}
				&:hover {
					opacity: 0.7;
				}
			}

			.orange-btn {
				min-width: 12rem;
				font-size: 1.6rem;
			}

			.favourite {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 3rem;
				height: 3rem;
				@extend .transition;
				@include bp(smd-max) {
					width: 2.4rem;
					height: 2.4rem;
					img {
						width: 2rem;
					}
				}
				&:hover {
					opacity: 0.7;
				}
			}

			.lang-wrapper {
				@include bp(smd-max) {
					display: none;
				}
				.lang-trigger {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					@extend .transition;
					&:hover {
						opacity: 0.7;
					}
				}
			}

			.profile-wrapper {
				position: relative;
				@include bp(smd-max) {
					display: none;
				}
				&:hover {
					.profile-trigger {
						&:after {
							@include rotate(180deg);
						}
					}
					.profile-dropdown {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
				.profile-trigger {
					font-family: $mediumFont;
					position: relative;
					padding-right: 2.1rem;
					cursor: pointer;
					@extend .transition;
					&:hover {
						opacity: 0.7;
					}
					&::after {
						content: "";
						position: absolute;
						right: 0;
						top: calc(50% - 0.2rem);
						width: 1.1rem;
						height: 0.6rem;
						@extend .transition;
						background-image: url("../images/down-arrow.svg");
						background-size: 1.1rem;
						background-repeat: no-repeat;
						background-position: center;
					}
				}
				.profile-dropdown {
					opacity: 0;
					visibility: hidden;
					transform: translateY(1rem);
					position: absolute;
					right: 0;
					width: 26.2rem;
					top: 100%;
					z-index: 1;
					background-color: $white;
					box-shadow: 0.4rem 0.4rem 1rem rgba($black, 0.1);
					border-radius: 0.4rem;
					font-size: 1.4rem;
					line-height: 2.4rem;
					@extend .transition;
					.rtl & {
						left: 0;
						right: auto;
					}
					.user-info {
						padding: 1.1rem 1rem 1.7rem;
						border-bottom: 0.1rem solid $lightGrey;
						.name {
							display: block;
							font-family: $mediumFont;
							@extend .truncated-text;
							margin-bottom: -0.3rem;
						}
						.email {
							display: block;
							@extend .truncated-text;
						}
					}
					.links {
						li {
							a {
								display: block;
								padding: 0.6rem 1rem 1.2rem;
							}
						}
					}
				}
			}

			.notification-wrapper {
				position: relative;
				&.noti-open {
					&::before {
						content: "";
						position: fixed;
						top: 12.2rem;
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba($black, 0.5);
						width: 100%;
						opacity: 0;
						visibility: hidden;
						@extend .transition;
						@include bp(sm-max) {
							opacity: 1;
							visibility: visible;
						}
					}
					.notification-dropdown {
						opacity: 1;
						visibility: visible;
						@include translateY(0);
					}
				}
				.unread-bubble {
					top: 0.3rem;
				}
				.notification-trigger {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					@extend .transition;
					cursor: pointer;
					position: relative;
					top: 0.3rem;
					@include bp(smd-max) {
						width: 2.4rem;
					}
					&:hover {
						opacity: 0.7;
					}
				}
				.notification-dropdown {
					filter: drop-shadow(1rem 1rem 2rem rgba($black, 0.15));
					border-radius: 1rem;
					position: absolute;
					right: 0;
					top: 100%;
					z-index: 1;
					width: 48.1rem;
					background-color: $white;
					opacity: 0;
					visibility: hidden;
					@include translateY(1rem);
					@extend .transition;
					@include bp(sm-max) {
						width: 100vw;
						right: -9.6rem;
						top: calc(100% + 2.8rem);
						border-radius: 0;
						filter: none;
					}
					.rtl & {
						left: 0;
						right: auto;
					}
					.notification-title {
						padding: 0.9rem 2rem 1.2rem;
						color: $white;
						border-radius: 1rem 1rem 0 0;
						background-color: $orange;
						font-size: 1.8rem;
						font-family: $mediumFont;
						@include bp(sm-max) {
							display: none;
						}
					}
					.notification-info {
						border-radius: 0 0 1rem 1rem;
						max-height: 61.2rem;
						overflow-y: auto;
						@include bp(lg-max) {
							max-height: 52rem;
						}
						&.no-data {
							height: 50.2rem;
							.mCSB_container {
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
						.noti-list {
							li {
								display: flex;
								padding: 1.4rem 2rem;
								border-bottom: 0.1rem solid $darkGrey2;
								@extend .transition;
								@include bp(sm-max) {
									padding: 1.4rem 1.6rem;
								}
								&:last-child {
									border-bottom: 0;
								}
								&.unread {
									background-color: $grey;
								}
								.icon {
									border: 0.1rem solid $darkGrey2;
									height: 4.8rem;
									width: 4.8rem;
									border-radius: 50%;
									display: inline-flex;
									align-items: center;
									justify-content: center;
									@extend .bg-props;
								}
								.noti-content {
									width: calc(100% - 4.8rem);
									padding-left: 1.5rem;
									@include bp(sm-max) {
										padding-left: 1rem;
									}
									.rtl & {
										padding-left: 0;
										padding-right: 1.5rem;
									}
									p {
										margin-bottom: 0.4rem;
									}
									.date {
										display: block;
										font-size: 1.4rem;
										line-height: 1.8rem;
										color: $darkGrey;
									}
								}
							}
						}
					}
				}
			}

			.search-wrapper {
				width: 35rem;
				max-width: 100%;
				position: relative;
				@include bp(smd-max) {
					flex: 1;
					margin-right: 2.4rem;
					.rtl & {
						margin-right: 0;
						margin-left: 0.8rem;
					}
				}
				&.search-results-visible {
					&::before {
						content: "";
						position: fixed;
						top: 12.2rem;
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba($black, 0.5);
						width: 100%;
						opacity: 0;
						visibility: hidden;
						@extend .transition;
						@include bp(sm-max) {
							opacity: 1;
							visibility: visible;
						}
					}
					.search-outputs {
						opacity: 1;
						visibility: visible;
						@include translateY(0);
					}
				}
				.search-input-wrapper {
					position: relative;
					.search-icon {
						position: absolute;
						left: 1.9rem;
						top: 1rem;
						line-height: 0;
						.rtl & {
							left: auto;
							right: 1.9rem;
						}
					}
					.form-control {
						padding-left: 4.8rem;
						height: 4.4rem;
						.rtl & {
							padding-right: 4.8rem;
							padding-left: 1.4rem;
						}
					}
				}
				.search-outputs {
					position: absolute;
					left: 0;
					right: 0;
					top: calc(100% + 0.4rem);
					background: $white;
					box-shadow: 1rem 1rem 2rem -0.4rem rgba($black, 0.15);
					border-radius: 1rem;
					height: 27.2rem;
					max-height: 27.2rem;
					overflow: auto;
					z-index: 1;
					opacity: 0;
					visibility: hidden;
					@include translateY(1rem);
					@extend .transition;
					@include bp(sm-max) {
						width: 100vw;
						left: -1.6rem;
						top: calc(100% + 1.9rem);
						border-radius: 0;
						box-shadow: none;
						height: 38rem;
						max-height: 38rem;
					}
					.mCSB_container {
						padding: 1.6rem 2rem;
						height: 100%;
					}
					h6 {
						margin-bottom: 2rem;
					}
					.search-results {
						li {
							margin-bottom: 2rem;
							&:last-child {
								margin-bottom: 0;
							}
							a {
								display: block;
							}
						}
					}
					.no-search-results {
						display: flex;
						flex-direction: column;
						height: 100%;
						justify-content: center;
						align-items: center;
						text-align: center;
						@include bp(sm-max) {
							max-width: 28rem;
							margin: 0 auto;
						}
						.icon {
							display: inline-block;
							margin-bottom: 1.7rem;
						}
						h4 {
							margin-bottom: 0.8rem;
							@include bp(sm-max) {
								margin-bottom: 0.6rem;
							}
						}
					}
				}
			}
		}
	}

	.bottom-header {
		height: 4.5rem;
		background: $black1;
		@extend .transition;
		@include bp(smd-max) {
			position: fixed;
			left: -100%;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			z-index: 9999;
			background: $white;
			overflow: auto;
			.rtl & {
				left: auto;
				right: -100%;
			}
			.menu-open & {
				left: 0;
			}
			.rtl.menu-open & {
				left: auto;
				right: 0;
			}
		}
		.container {
			height: 100%;
			@include bp(smd-max) {
				height: auto;
			}
		}
		.mob-menu-close {
			display: none;
			height: 1.5rem;
			width: 1.5rem;
			position: absolute;
			right: 2rem;
			top: 3rem;
			background-image: url("../images/close-black.svg");
			background-size: 1.5rem;
			background-repeat: no-repeat;
			background-position: center;
			.rtl & {
				right: auto;
				left: 1rem;
			}
			@include bp(smd-max) {
				display: block;
			}
		}
		.mob-block {
			display: none;
			@include bp(smd-max) {
				display: block;
			}
		}
		.account-mob-menu {
			.account-title {
				margin: 0 -1.6rem;
				padding: 1.1rem 1.6rem 1.7rem;
				background: $grey;
				font-family: $mediumFont;
				font-size: 1.6rem;
				line-height: 2.4rem;
			}
			.account-menu {
				&.site-menu {
					padding-bottom: 0;
					padding-top: 1.7rem;
					> li {
						&.submenu-expanded {
							> a {
								color: $black1;
								font-family: $regularFont;
							}
						}
						> a {
							display: flex;
							.icon {
								display: inline-flex;
								align-items: center;
								justify-content: center;
								height: 2.4rem;
								width: 2.4rem;
								margin-right: 1.2rem;
								position: relative;
								top: 0.2rem;
								.rtl & {
									margin-right: 0;
									margin-left: 1.2rem;
								}
							}
						}
						.submenu-wrapper {
							padding: 0 0 0 3.6rem;
						}
					}
				}
			}
		}
		.user-info-wrapper {
			display: flex;
			align-items: center;
			margin: 0 -1.6rem;
			padding: 1.7rem 4.5rem 1.7rem 1.6rem;
			background-color: $grey;
			.rtl & {
				padding: 1.7rem 1.6rem 1.7rem 4.5rem;
			}
			.dp {
				display: inline-flex;
				height: 6rem;
				width: 6rem;
				border-radius: 50%;
				filter: drop-shadow(0 0.1rem 0.4rem rgba($black, 0.1));
				padding: 0.3rem;
				background: $white;
				img {
					height: 5.4rem;
					width: 5.4rem;
					border-radius: 50%;
					object-fit: cover;
				}
			}
			.user-info {
				width: calc(100% - 6rem);
				padding-left: 1.4rem;
				.rtl & {
					padding-left: 0;
					padding-right: 1.4rem;
				}
				h6 {
					font-size: 1.8rem;
					line-height: 2.4rem;
					@extend .truncated-text;
					margin-bottom: 0;
					font-family: $semiboldFont;
				}
				a {
					color: $orange;
					font-size: 1.4rem;
				}
			}
		}
		.site-menu {
			display: flex;
			justify-content: center;
			height: 100%;
			@include bp(smd-max) {
				display: block;
				padding: 2.3rem 0 4rem;
				height: auto;
			}
			> li {
				margin-right: 3.7rem;
				position: relative;
				.rtl & {
					margin-left: 3.7rem;
					margin-right: 0;
					@include bp(smd-max) {
						margin-left: 0;
					}
				}
				@include bp(smd-max) {
					margin-right: 0;
					border-bottom: 0.1rem solid $darkGrey2;
					padding-bottom: 2.2rem;
					margin-bottom: 1.7rem;
				}
				&:last-child {
					margin: 0;
				}
				&.submenu-expanded {
					@include bp(smd-max) {
						> .arrow {
							@include rotate(180deg);
							right: -0.1rem;
						}
						> a {
							color: $orange;
							font-family: $mediumFont;
						}
					}
				}
				.arrow {
					position: absolute;
					right: 0;
					top: calc(50% - 0.3rem);
					width: 1.1rem;
					height: 0.6rem;
					z-index: 1;
					@extend .transition;
					background-image: url("../images/down-arrow-white.svg");
					background-size: 1.1rem;
					background-repeat: no-repeat;
					background-position: center;
					display: none;
					cursor: pointer;
					.rtl & {
						right: auto;
						left: 0;
					}
					@include bp(smd-max) {
						background-image: url("../images/down-arrow.svg");
						top: 0.3rem;
						right: 0;
						width: 2.4rem;
						height: 2.4rem;
						background-size: 1.2rem;
					}
				}
				&:hover {
					.submenu-wrapper {
						@include bp(smd-min) {
							opacity: 1;
							visibility: visible;
							@include translateY(0);
						}
					}
				}
				&.has-submenu {
					&:hover {
						.arrow {
							@include bp(smd-min) {
								@include rotate(180deg);
							}
						}
					}
					> a {
						padding-right: 1.9rem;
						.rtl & {
							padding-right: 0;
							padding-left: 1.9rem;
						}
						@include bp(smd-max) {
							padding-right: 2.4rem;
							.rtl & {
								padding-left: 2.4rem;
							}
						}
					}
					.arrow {
						display: block;
					}
				}
				> a {
					color: $white;
					font-family: $mediumFont;
					display: block;
					padding-top: 0.8rem;
					height: 4.5rem;
					position: relative;
					font-size: 1.6rem;
					line-height: 2.4rem;
					@include bp(smd-max) {
						color: $black1;
						height: auto;
						padding: 0;
						font-family: $regularFont;
					}
				}
				.submenu-wrapper {
					position: absolute;
					left: 0;
					background: $white;
					box-shadow: 0 1rem 3rem rgba($black, 0.1);
					border-radius: 1rem;
					width: 84rem;
					padding: 3.7rem 5rem;
					z-index: 1;
					@extend .transition;
					.rtl & {
						right: 0;
						left: auto;
					}
					@include bp(smd-min) {
						opacity: 0;
						visibility: hidden;
						@include translateY(1rem);
					}
					@include bp(lg-max) {
						left: -4rem;
					}
					@include bp(smd-max) {
						width: 100%;
						box-shadow: none;
						padding: 0;
						position: static;
						padding: 1.6rem 0 0 2rem;
						transition: none;
						display: none;
						.rtl & {
							padding-left: 0;
							padding-right: 2rem;
						}
					}
					.see-all-row {
						display: flex;
						justify-content: flex-end;
						margin-top: 3rem;
						@include bp(smd-max) {
							display: none;
						}
						.see-all {
							font-size: 1.8rem;
							line-height: 2rem;
							color: $orange;
							font-family: $mediumFont;
							&:hover {
								opacity: 0.8;
							}
						}
					}
					.submenu-row {
						display: flex;
						flex-wrap: wrap;
						margin: 0 -2rem;
						@include bp(smd-max) {
							margin: 0;
						}
						.submenu-col {
							width: 33.33%;
							padding: 0 2rem;
							@include bp(smd-max) {
								width: 100%;
								padding: 0;
							}
							&:last-child {
								.category-outer {
									&:last-child {
										@include bp(smd-max) {
											margin-bottom: 0;
										}
									}
								}
							}
							.category-outer {
								margin-bottom: 4rem;
								@include bp(smd-max) {
									margin-bottom: 1.7rem;
								}
								&:last-child {
									@include bp(smd-min) {
										margin-bottom: 0;
									}
								}
								&.category-expanded {
									@include bp(smd-max) {
										.category-title {
											font-family: $mediumFont !important;
											color: $orange !important;
											.arrow {
												@include rotate(180deg);
											}
										}
									}
								}
								.category-title {
									color: $orange;
									margin-bottom: 1.4rem;
									@include bp(smd-max) {
										color: $black1;
										margin: 0;
										font-family: $regularFont;
										position: relative;
										padding-right: 2.4rem;
										.rtl & {
											padding-right: 0;
											padding-left: 2.4rem;
										}
									}
									.arrow {
										display: none;
										top: 0.3rem;
										@include bp(smd-max) {
											display: block;
										}
									}
								}
								.category-list {
									@include bp(smd-min) {
										display: block !important;
									}
									@include bp(smd-max) {
										display: none;
										padding-top: 1.6rem;
									}
									ul {
										li {
											margin-bottom: 1rem;
											@include bp(smd-max) {
												margin-bottom: 1.6rem;
											}
											&:last-child {
												margin-bottom: 0;
											}
											a {
												display: block;
												font-size: 1.6rem;
												line-height: 2.4rem;
												@include bp(smd-max) {
													color: $darkGrey;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.hamburger-menu {
	width: 2rem;
	height: 1.8rem;
	z-index: 99;
	margin-right: 2.3rem;
	.rtl & {
		margin-right: 0;
		margin-left: 2.3rem;
	}
	@include bp(smd-min) {
		display: none;
	}
	span {
		width: 100%;
		height: 0.2rem;
		background: $black;
		display: block;
		border-radius: 0.3rem;
		@extend .transition;
		.menu-open & {
			&:first-child {
				transform: translate(0, 0.8rem) rotate(45deg);
			}
			&:last-child {
				transform: translate(0, 0.4rem) rotate(-45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
		}
		&:nth-child(2) {
			@include translateY(0.4rem);
		}
		&:last-child {
			@include translateY(0.8rem);
		}
	}
}
