.no-data-block {
	text-align: center;
	width: 32.8rem;
	margin: 0 auto;
	max-width: 100%;
	.no-data-icon {
		display: inline-flex;
		margin-bottom: 1.4rem;
	}
	h4 {
		text-transform: capitalize;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	p {
		margin-bottom: 2.6rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.orange-btn {
		width: 100%;
	}
}

.section-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4.7rem;
	@include bp(lg-max) {
		margin-bottom: 4rem;
	}
	@include bp(smd-max) {
		margin-bottom: 3rem;
	}
	@include bp(sm-max) {
		margin-bottom: 1.6rem;
	}
	h2 {
		margin: 0;
		@include bp(smd-max) {
			font-size: 2.4rem;
			line-height: 3rem;
		}
		@include bp(sm-max) {
			font-size: 2rem;
			line-height: 2.8rem;
		}
	}
	.right-title {
		.see-all {
			color: $orange;
			font-size: 1.8rem;
			font-family: $mediumFont;
			@include bp(lg-max) {
				font-size: 1.6rem;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
}

.product-card {
	position: relative;
	&:hover {
		.prod-thumb {
			img {
				@include scale(1.05);
			}
		}
	}
	&.is-horizontal {
		&.sm-width {
			.prod-status-thumb {
				width: 12rem;
				@include bp(sm-max) {
					width: 10rem;
				}
			}
			.prod-content {
				width: calc(100% - 12rem);
				padding-left: 2rem;
				padding-right: 0;
				@include bp(sm-max) {
					width: calc(100% - 10rem);
					padding-left: 1.6rem;
					.rtl & {
						padding-right: 1.6rem;
					}
				}
				.rtl & {
					padding-left: 0;
					padding-right: 2rem;
				}
				h5 {
					font-size: 1.6rem;
					line-height: 2.4rem;
					padding: 0;
					@include bp(sm-max) {
						font-size: 1.4rem;
						line-height: 2rem;
					}
				}
				.price {
					font-size: 1.8rem;
					line-height: 2.4rem;
					@include bp(sm-max) {
						font-size: 1.6rem;
						line-height: 2rem;
						margin-bottom: 0.9rem;
					}
					.discounted {
						font-size: 1.6rem;
						@include bp(sm-max) {
							font-size: 1.4rem;
							line-height: 1.8rem;
							margin-left: 0.4rem;
							.rtl & {
								margin-left: 0;
								margin-right: 0.4rem;
							}
						}
					}
				}
				.order-info {
					min-height: inherit;
				}
				.qty-block {
					margin-top: 1.1rem;
					@include bp(sm-max) {
						margin-top: 0.9rem;
					}
				}
			}
		}
		.prod-link {
			display: flex;
			flex-wrap: wrap;
		}
		.prod-status-thumb {
			width: 18.6rem;
			@include bp(sm-max) {
				width: 10rem;
			}
		}
		.prod-thumb {
			margin: 0;
		}
		.prod-content {
			width: calc(100% - 18.6rem);
			padding: 0 3rem;
			@include bp(sm-max) {
				width: calc(100% - 10rem);
				padding: 0 0 0 1.5rem;
				margin-top: -0.5rem;
				.rtl & {
					padding-left: 0;
					padding-right: 1.5rem;
				}
			}
			&.has-btns {
				padding-right: 0;
				display: flex;
				flex-wrap: wrap;
				.rtl & {
					padding-left: 0;
					padding-right: 3rem;
					@include bp(sm-max) {
						padding-right: 1.5rem;
					}
				}
				.prod-left-content {
					width: calc(100% - 29.4rem);
					padding-right: 1rem;
					@include bp(lg-max) {
						width: 100%;
						padding-right: 0;
					}
					h5 {
						padding: 0;
					}
				}
				.order-info-btns {
					display: flex;
					justify-content: flex-end;
					width: 29.4rem;
					@include bp(lg-max) {
						width: 100%;
						margin-top: 1.5rem;
						justify-content: flex-start;
					}
					@include bp(sm-max) {
						margin: 1.5rem 0 0 -11.5rem;
						width: calc(100% + 11.5rem);
						justify-content: space-between;
						.rtl & {
							margin-right: -11.5rem;
							margin-left: 0;
						}
					}
					.orange-btn {
						padding: 0;
						width: 14rem;
						margin-right: 1.4rem;
						.rtl & {
							margin-right: 0;
							margin-left: 1.4rem;
						}
						@include bp(sm-max) {
							width: calc(50% - 1rem);
							margin: 0;
							.rtl & {
								margin-left: 0;
							}
						}
						&:last-child {
							margin: 0;
						}
					}
				}
			}
			h5 {
				overflow: visible;
				text-overflow: ellipsis;
				white-space: inherit;
				@include bp(sm-max) {
					padding-right: 3.7rem;
					margin-bottom: 0.8rem;
					.rtl & {
						padding-right: 0;
						padding-left: 3.7rem;
					}
				}
				a {
					overflow: visible;
					text-overflow: ellipsis;
					white-space: inherit;
				}
			}
			.price {
				padding-right: 0;
				.rtl & {
					padding-left: 0;
				}
				@include bp(sm-max) {
					font-size: 1.6rem;
					line-height: 2rem;
					margin-bottom: 0.6rem;
				}
			}
			.order-info {
				color: $black2;
				padding-top: 0.2rem;
				@include bp(sm-max) {
					padding-top: 0;
					min-height: 4.8rem;
				}
				p {
					margin-bottom: 0.8rem;
					@include bp(sm-max) {
						margin-bottom: 0.4rem;
					}
					&:last-child {
						margin-bottom: 0;
					}
					&.note {
						font-size: 1.4rem;
						line-height: 2.2rem;
						strong {
							font-family: $mediumFont;
						}
					}
					.time {
						font-family: $mediumFont;
					}
				}
				.seller-policy {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					justify-content: space-between;
					p {
						margin: 0;
					}
				}
			}
		}
	}
	&.is-category {
		.prod-thumb {
			padding-bottom: 150%;
			@include bp(sm-max) {
				margin-bottom: 1.1rem;
			}
			&.sm {
				padding-bottom: 124.5%;
			}
			&.is-square {
				padding-bottom: 100%;
			}
		}
		.prod-content {
			h5 {
				@include bp(smd-max) {
					font-size: 1.6rem;
					line-height: 2.4rem;
				}
			}
		}
	}
	&.disabled {
		cursor: not-allowed;
		&:hover {
			.prod-thumb {
				img {
					@include scale(1);
				}
			}
		}
		.prod-link {
			opacity: 0.4;
			pointer-events: none;
		}
	}
	.prod-link {
		display: block;
	}
	.delete-prod {
		display: inline-flex;
		height: 3.2rem;
		width: 3.2rem;
		align-items: center;
		justify-content: center;
		background: $grey;
		border-radius: 50%;
		position: absolute;
		right: 0;
		top: 0.2rem;
		.rtl & {
			right: auto;
			left: 0;
		}
		@include bp(sm-max) {
			height: 2.4rem;
			width: 2.4rem;
			top: -0.2rem;
		}
		img {
			width: 1.8rem;
			@include bp(sm-max) {
				width: 1.4rem;
			}
		}
	}
	.favourite {
		position: absolute;
		right: 1.6rem;
		top: 1.6rem;
		line-height: 0;
		background: #fff;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 4.8rem;
		width: 4.8rem;
		z-index: 1;
		.rtl & {
			right: auto;
			left: 1.6rem;
		}
		@include bp(smd-max) {
			height: 3.4rem;
			width: 3.4rem;
			top: 1rem;
			right: 0.5rem;
			.rtl & {
				left: 0.5rem;
			}
		}
		img {
			width: 2.8rem;
			@include bp(smd-max) {
				width: 2rem;
			}
		}
	}
	.no-stock {
		display: flex;
		text-align: center;
		justify-content: center;
		color: $red;
		font-family: $mediumFont;
		margin-top: 0.6rem;
		@include bp(sm-max) {
			margin-top: 0.4rem;
			font-size: 1.2rem;
		}
	}
	.prod-thumb {
		position: relative;
		padding-bottom: 100%;
		overflow: hidden;
		display: block;
		margin-bottom: 1.6rem;
		border-radius: 0.6rem;
		@include bp(sm-max) {
			margin-bottom: 0.7rem;
		}
		&.sm {
			padding-bottom: 93.2%;
			@include bp(smd-max) {
				padding-bottom: 100%;
			}
		}
		&.lg {
			padding-bottom: 131.3%;
		}
		&.has-radius {
			border-radius: 0.6rem 0.6rem 0 0;
			@include bp(sm-max) {
				border-radius: 0.6rem;
			}
		}
		img {
			@extend .absolute-pos;
			@extend .cover-fit;
			@extend .transition;
		}
	}
	.prod-content {
		color: $black1;
		h5 {
			@extend .truncated-text;
			margin-bottom: 0.6rem;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				color: $black1;
				@extend .truncated-text;
				display: block;
			}
		}
		.price {
			display: flex;
			align-items: center;
			color: $orange;
			@extend .h5;
			font-family: $semiboldFont;
			.discount {
				display: inline-block;
				margin-left: 1rem;
				font-size: 1.6rem;
				color: $darkGrey;
				font-family: $mediumFont;
				.rtl & {
					margin-right: 1rem;
					margin-left: 0;
				}
				@include bp(smd-max) {
					font-size: 1.4rem;
				}
				@include bp(sm-max) {
					font-size: 1.2rem;
					margin-left: 0.6rem;
					.rtl & {
						margin-left: 0;
						margin-right: 0.6rem;
					}
				}
			}
			.discounted {
				font-size: 1.8rem;
				line-height: 2.6rem;
				color: $darkGrey;
				margin-left: 1rem;
				font-family: $regularFont;
				position: relative;
				position: relative;
				top: 0;
				.rtl & {
					margin-left: 0;
					margin-right: 1rem;
				}
				@include bp(sm-max) {
					font-size: 1.4rem;
					line-height: 2.4rem;
					margin-left: 0.8rem;
					top: 0.1rem;
					.rtl & {
						margin-left: 0;
						margin-right: 0.8rem;
					}
				}
				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0.8rem;
					height: 0.1rem;
					background-color: $darkGrey;
					display: block;
				}
			}
		}
		.qty-block {
			margin-top: 1.6rem;
			@include bp(sm-max) {
				justify-content: flex-start;
				margin-top: 0;
			}
			.qty-outer {
				width: 12rem;
				@include bp(sm-max) {
					width: 9.2rem;
					margin-right: 0.8rem;
					.rtl & {
						margin-right: 0;
						margin-left: 0.8rem;
					}
					&:last-child {
						margin: 0;
					}
				}
				@include bp(xxs-max) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 1rem;
					.rtl & {
						margin-left: 0;
					}
				}
				&.inline {
					width: 100%;
					.form-group {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						> label {
							font-family: $regularFont;
							color: $black2;
							margin: 0 1.9rem 0 0;
							position: relative;
							top: -0.3rem;
							.rtl & {
								margin-right: 0;
								margin-left: 1.9rem;
							}
						}
					}
				}
				input.form-control {
					@include bp(sm-max) {
						padding: 0;
					}
				}
			}
		}
		.prod-content-btns {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 2.3rem;
			@include bp(smd-max) {
				margin-top: 1.6rem;
			}
			@include bp(sm-max) {
				margin-left: -11.5rem;
				.rtl & {
					margin-left: 0;
					margin-right: -11.5rem;
				}
			}
			&.full-width {
				.orange-btn {
					width: 100%;
				}
			}
			.orange-btn {
				padding: 0;
				width: calc(50% - 0.7rem);
			}
		}
	}
	.mob-order-info {
		width: 100%;
		margin-top: 1.3rem;
		color: $black2;
		display: none;
		@include bp(sm-max) {
			display: block;
		}
		p {
			margin-bottom: 0.5rem;
			&:last-child {
				margin-bottom: 0;
			}
			.time {
				font-family: $mediumFont;
			}
			strong {
				font-family: $mediumFont;
			}
		}
		.seller-policy {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
			p {
				margin: 0;
			}
		}
	}
}

.product-masonary-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem;
	@include bp(sm-max) {
		margin: 0 -1rem;
	}
	.masonary-col {
		width: 25%;
		padding: 0 1.5rem;
		@include bp(smd-max) {
			width: 50%;
		}
		@include bp(sm-max) {
			padding: 0 1rem;
		}
		.product-card {
			margin-bottom: 4.4rem;
			@include bp(sm-max) {
				margin-bottom: 2.2rem;
			}
			&:last-child {
				margin-bottom: 0;
			}
			.prod-thumb {
				&.sm {
					@include bp(sm-max) {
						padding-bottom: 93.2%;
					}
				}
			}
		}
	}
}

.breadcrumb {
	margin: 0;
	li {
		margin-right: 0.8rem;
		padding-right: 2rem;
		position: relative;
		font-family: $mediumFont;
		@include bp(sm-max) {
			margin-right: 0.6rem;
			padding-right: 1.7rem;
		}
		&:first-child {
			.rtl & {
				margin-right: 0;
				padding-right: 0;
				&::after {
					display: none;
				}
			}
		}
		&:last-child {
			margin-right: 0;
			padding-right: 0;
			.rtl & {
				margin-right: 0.8rem;
				padding-right: 2rem;
				@include bp(sm-max) {
					margin-right: 0.6rem;
					padding-right: 1.8rem;
				}
				&::after {
					display: block;
				}
			}
			&::after {
				display: none;
			}
		}
		&.fixed-width {
			width: 10.2rem;
			@extend .truncated-text;
			a {
				@extend .truncated-text;
				max-width: 100%;
			}
		}
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 1rem;
			width: 1.2rem;
			height: 0.8rem;
			@include rotate(-90deg);
			background-image: url("../images/down-arrow.svg");
			background-size: 1.2rem;
			background-repeat: no-repeat;
			background-position: center;
			@include bp(sm-max) {
				top: 0.8rem;
			}
			.rtl & {
				@include rotate(90deg);
			}
		}
	}
}

.sidebar-wrapper {
	position: fixed;
	right: -44.9rem;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 44.9rem;
	max-width: 100%;
	background-color: $white;
	z-index: 999;
	@extend .transition;
	.rtl & {
		right: auto;
		left: -44.9rem;
		@include bp(sm-max) {
			left: -100%;
		}
	}
	@include bp(sm-max) {
		width: 100%;
		right: -100%;
	}
	&.open {
		right: 0;
		.rtl & {
			right: auto;
			left: 0;
		}
	}
	.sidebar-search-wrapper {
		padding: 4.6rem 3rem 3rem;
		border-bottom: 0.1rem solid $darkGrey2;
		@include bp(sm-max) {
			padding: 1.8rem 1.6rem;
		}
		.title {
			display: flex;
			align-items: center;
			@include bp(sm-max) {
				display: none;
			}
			.close-sidebar {
				line-height: 0;
				position: relative;
				top: 0.4rem;
				height: 2.4rem;
				width: 2.4rem;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				@include bp(sm-max) {
					top: 0.3rem;
				}
			}
			h5 {
				width: calc(100% - 2.4rem);
				padding-left: 1.2rem;
				.rtl & {
					padding-left: 0;
					padding-right: 1.2rem;
				}
			}
		}
	}
	.search-input-wrapper {
		position: relative;
		margin-top: 3.4rem;
		@include bp(sm-max) {
			margin-top: 0;
		}
		.close-sidebar {
			display: none;
			position: absolute;
			left: 0.1rem;
			top: 0.1rem;
			height: 4.2rem;
			width: 4.4rem;
			align-items: center;
			justify-content: center;
			z-index: 1;
			.rtl & {
				left: auto;
				right: 0.1rem;
			}
			@include bp(sm-max) {
				display: flex;
			}
		}
		.search-icon {
			position: absolute;
			left: 1.7rem;
			top: 1.2rem;
			line-height: 0;
			@include bp(sm-max) {
				display: none;
			}
			.rtl & {
				left: auto;
				right: 1.9rem;
			}
		}
		.coupon-apply {
			position: relative;
			.form-control {
				padding-right: 7rem;
			}
			.primary-link {
				position: absolute;
				right: 1.8rem;
				top: 1rem;
			}
		}
		.coupon-error {
			margin-top: 0.7rem;
			color: $red;
		}
		.form-control {
			padding-left: 4.8rem;
			height: 4.4rem;
			.rtl & {
				padding-right: 4.8rem;
				padding-left: 1.4rem;
			}
		}
	}
	.current-location {
		display: flex;
		align-items: center;
		padding: 1.5rem 3rem 1.8rem;
		color: $orange;
		font-family: $mediumFont;
		border-bottom: 0.1rem solid $darkGrey2;
		@include bp(sm-max) {
			padding: 1.7rem 1.6rem 2.2rem;
		}
		.icon {
			line-height: 0;
			margin-right: 0.8rem;
			position: relative;
			top: 0.1rem;
			.rtl & {
				margin-right: 0;
				margin-left: 0.8rem;
			}
			@include bp(sm-max) {
				margin-right: 0.6rem;
				width: 1.6rem;
				top: 0;
				.rtl & {
					margin-right: 0;
					margin-left: 0.6rem;
					top: 0.1rem;
				}
			}
		}
		h6 {
			@include bp(sm-max) {
				font-size: 1.4rem;
				line-height: 2rem;
			}
		}
	}
	.search-results {
		height: calc(100vh - 23.8rem);
		overflow: auto;
		padding: 1.7rem 0;
		@include bp(sm-max) {
			height: calc(100vh - 14.1rem);
		}
		&.lg {
			height: calc(100vh - 18.1rem);
			@include bp(sm-max) {
				height: calc(100vh - 8.1rem);
			}
		}
		.no-search-results {
			height: calc(100vh - 25.4rem);
			display: flex;
			align-items: center;
			@include bp(sm-max) {
				height: calc(100% - 15.5rem);
			}
		}
		h6 {
			color: $darkGrey;
			font-size: 1.6rem;
			line-height: 2rem;
			margin-bottom: 1.9rem;
		}
		.mCSB_container {
			padding: 0 3rem;
			@include bp(sm-max) {
				padding: 0 1.6rem;
			}
		}
		.search-location-outer {
			font-size: 1.4rem;
			line-height: 2.2rem;
			margin-bottom: 1.2rem;
			padding-bottom: 1.8rem;
			border-bottom: 0.1rem solid $darkGrey2;
			display: block;
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}
			.name {
				font-family: $mediumFont;
				display: block;
				@extend .truncated-text;
				margin-bottom: 0.5rem;
			}
			.address {
				display: block;
				@extend .truncated-text;
				color: $darkGrey;
			}
		}
		.coupon-outer {
			padding-bottom: 2.2rem;
			margin-bottom: 1.8rem;
			border-bottom: 0.1rem solid $darkGrey2;
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: 0;
			}
			&.inactive {
				opacity: 0.7;
				cursor: not-allowed;
				.coupon-top {
					.primary-link {
						pointer-events: none;
					}
				}
			}
			.coupon-top {
				margin-bottom: 1rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.code {
					display: inline-flex;
					background-color: $lightOrange2;
					border-radius: 0.6rem;
					padding: 0 1rem;
					font-family: $mediumFont;
					height: 3.6rem;
					padding-top: 0.7rem;
				}
			}
			.coupon-info {
				color: $darkGrey;
			}
		}
	}
}

.custom-pagination {
	margin-top: 6rem;
	padding-top: 0.5rem;
	border-top: 0.1rem solid $lightGrey1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include bp(sm-max) {
		margin-top: 4rem;
		padding-top: 0.7rem;
	}
	.prev-nxt-btn {
		padding: 0.7rem 4rem 0.9rem 1.3rem;
		font-size: 1.4rem;
		line-height: 2rem;
		font-family: $mediumFont;
		position: relative;
		color: $black1;
		&.prev {
			padding: 0.7rem 1.3rem 0.9rem 4rem;
			&:after {
				right: auto;
				left: 1.2rem;
				@include rotate(90deg);
			}
		}
		&:after {
			content: "";
			position: absolute;
			top: calc(50% - 0.8rem);
			right: 1.2rem;
			height: 2rem;
			width: 2rem;
			background-image: url("../images/down-arrow.svg");
			background-size: 1.5rem;
			background-repeat: no-repeat;
			background-position: center;
			@include rotate(-90deg);
		}
	}
	.page-list {
		display: none;
		font-size: 1.4rem;
		line-height: 2rem;
		font-family: $mediumFont;
		@include bp(sm-max) {
			display: block;
		}
	}
	.pages {
		display: flex;
		align-items: center;
		@include bp(sm-max) {
			display: none;
		}
		li {
			height: 4rem;
			width: 4rem;
			text-align: center;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.2rem;
			&:last-child {
				margin-right: 0;
			}
			a {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				height: 4rem;
				width: 4rem;
				border-radius: 0.4rem;
				font-size: 1.4rem;
				line-height: 2rem;
				font-family: $mediumFont;
				color: $black1;
				padding: 0.7rem 0;
				&.active {
					background: $lightOrange1;
					color: $orange;
				}
				&:hover {
					background-color: $grey;
					color: $black1;
				}
			}
		}
	}
}

.id-card-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem;
	.id-card-col {
		width: 50%;
		padding: 0 1.5rem;
		@include bp(xs-max) {
			width: 100%;
			margin-bottom: 1.4rem;
			&:last-child {
				margin: 0;
			}
		}
		.example-link {
			margin-top: 0.3rem;
		}
		.card-upload-wrapper {
			display: flex;
			border-radius: 0.8rem;
			background: $grey;
			position: relative;
			padding-bottom: 56.4%;
			overflow: hidden;
			.id-img {
				@extend .absolute-pos;
				@extend .cover-fit;
			}
			.id-placeholder {
				@extend .absolute-pos;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.icon {
					display: inline-block;
					margin-bottom: 0.8rem;
				}
				span {
					display: block;
					color: $darkGrey;
					font-size: 1.4rem;
					line-height: 2rem;
				}
			}
		}
	}
}

.system-msg {
	display: flex;
	flex-wrap: wrap;
	padding: 1.1rem 1.5rem 1.8rem;
	background: $green;
	border-radius: 0.6rem;
	box-shadow:
		0 0.2rem 0.4rem -0.2rem rgba(16, 24, 40, 0.06),
		0 0.4rem 0.8rem -0.2rem rgba(16, 24, 40, 0.1);
	@include bp(sm-max) {
		padding: 0.9rem 1.5rem 1.8rem;
		margin-bottom: 2rem;
	}
	&.is-warning {
		background: $warning;
	}
	&.is-error {
		background: $lightRed;
	}
	.icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		height: 3rem;
		width: 3rem;
		position: relative;
		top: 0.3rem;
		@include bp(sm-max) {
			top: 0.7rem;
		}
	}
	.msg-info {
		width: calc(100% - 3rem);
		padding-left: 1.4rem;
		.rtl & {
			padding-left: 0;
			padding-right: 1.4rem;
		}
		h6 {
			margin-bottom: 0.6rem;
			letter-spacing: 0.008rem;
			@include bp(sm-max) {
				margin-bottom: 0.3rem;
			}
		}
	}
}

.faq-wrapper {
	.faq-outer {
		padding-bottom: 2.3rem;
		margin-bottom: 1.6rem;
		border-bottom: 0.1rem solid $darkGrey2;
		@include bp(sm-max) {
			padding-bottom: 2.4rem;
			margin-bottom: 1.8rem;
		}
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: 0;
		}
		&.faq-expanded {
			.faq-title {
				&::after {
					@include rotate(180deg);
				}
			}
		}
		.faq-title {
			position: relative;
			padding-right: 3rem;
			.rtl & {
				padding-right: 0;
				padding-left: 3rem;
			}
			cursor: pointer;
			&:after {
				content: "";
				position: absolute;
				right: 0.1rem;
				top: 0.6rem;
				width: 2rem;
				height: 2rem;
				z-index: 1;
				background-image: url("../images/down-arrow.svg");
				background-size: 1.1rem;
				background-repeat: no-repeat;
				background-position: 50%;
				@extend .transition;
				@include bp(sm-max) {
					top: 0.2rem;
					background-size: 1.2rem;
				}
				.rtl & {
					right: auto;
					left: 0.1rem;
				}
			}
		}
		.faq-body {
			color: $darkGrey;
			display: none;
			padding-top: 0.8rem;
			p {
				@include bp(sm-max) {
					line-height: 2.2rem;
				}
			}
			.other-form {
				margin-top: 0.4rem;
				textarea.form-control {
					min-height: 8rem;
					resize: none;
					@include bp(sm-max) {
						min-height: 12.9rem;
					}
				}
				.submit-btn-block {
					margin-top: 1.5rem;
					display: flex;
					justify-content: center;
					@include bp(sm-max) {
						margin-top: 0.7rem;
					}
					.orange-btn {
						width: 100%;
					}
				}
			}
		}
	}
}

.page-not-found {
	padding: 12rem 0;
	background-image: url("../images/404-bg-pattern.svg");
	@extend .bg-props;
	text-align: center;
	@include bp(smd-max) {
		padding: 10rem 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0;
	}
	.page-images {
		margin-bottom: 4.5rem;
		@include bp(sm-max) {
			margin-bottom: 8.6rem;
		}
		.thumb {
			margin-bottom: 2.7rem;
		}
		.logo {
			display: block;
			img {
				width: 22.3rem;
				max-width: 100%;
			}
		}
	}
	h4 {
		margin-bottom: 1.1rem;
		@include bp(sm-max) {
			margin-bottom: 0.7rem;
		}
	}
	p {
		margin-bottom: 3.4rem;
		@include bp(sm-max) {
			margin-bottom: 2.3rem;
		}
	}
	.orange-btn {
		width: 36rem;
		max-width: 100%;
	}
}

.no-search-placeholder {
	padding: 14.6rem 0;
	text-align: center;
	@include bp(smd-max) {
		padding: 10rem 0;
	}
	@include bp(sm-max) {
		padding: 8rem 0;
	}
	.no-data-block {
		@include bp(sm-max) {
			max-width: 31rem;
		}
	}
}
