.cart-page {
	padding: 2.6rem 0 12rem;
	@include bp(sm-max) {
		padding: 2rem 0 8rem;
	}
	.breadcrumb {
		margin-bottom: 3rem;
	}
	.no-data-block {
		margin-top: 10rem;
		@include bp(smd-max) {
			margin-top: 8rem;
		}
	}
}

.cart-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem;
	.products-col {
		width: 66.67%;
		padding: 0 1.5rem;
		@include bp(smd-max) {
			width: 100%;
			padding: 0;
		}
		.inner-prod-row {
			padding-bottom: 3rem;
			margin-bottom: 3rem;
			border-bottom: 0.1rem solid $darkGrey2;
			@include bp(smd-max) {
				padding: 0 1.6rem 1.9rem;
				margin-bottom: 2rem;
			}
			&:last-child {
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: 0;
			}
		}
	}
	.summary-col {
		width: 33.3%;
		padding: 0 1.5rem;
		@include bp(smd-max) {
			width: 100%;
			padding: 0;
			margin-top: 1.9rem;
			border-top: 0.1rem solid $darkGrey2;
		}
	}
}

.cart-summary-wrapper {
	border: 0.1rem solid $darkGrey2;
	border-radius: 1rem;
	padding-top: 0.8rem;
	background: $grey;
	@include bp(smd-max) {
		background: transparent;
		border: 0;
		padding: 0;
	}
	.inner-cart-block {
		padding: 1.8rem 1.6rem 2.7rem;
		border-bottom: 0.1rem solid $darkGrey2;
		@include bp(sm-max) {
			padding: 2.6rem 1.6rem 3.3rem;
		}
		&:last-child {
			border-bottom: 0;
		}

		.radio-wrapper {
			&.inline {
				@include bp(sm-max) {
					flex-direction: column;
				}
			}
			.radio-outer {
				@include bp(sm-max) {
					margin-right: 0;
				}
				label {
					display: inline-flex;
					.icon {
						margin-left: 0.8rem;
						.rtl & {
							margin-left: 0;
							margin-right: 0.8rem;
						}
					}
				}
			}
		}

		.summary-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 1.8rem;
			@include bp(sm-max) {
				margin-bottom: 1.4rem;
			}
			h6 {
				margin: 0;
			}
			.primary-link {
				font-size: 1.6rem;
				line-height: 2rem;
			}
		}
		.name-address {
			.name {
				@extend .h6;
				margin-bottom: 0.6rem;
				@include bp(sm-max) {
					font-size: 1.4rem;
					line-height: 1.8rem;
				}
			}
			.address {
				color: $darkGrey;
				@include bp(sm-max) {
					line-height: 2.2rem;
				}
			}
		}
		.payment-instructions {
			margin-top: 2.6rem;
			font-size: 1.2rem;
			line-height: 1.8rem;
			color: $black2;
			@include bp(sm-max) {
				font-size: 1rem;
				line-height: 1.5rem;
				margin-top: 3.3rem;
			}
			p {
				margin-bottom: 0.5rem;
				@include bp(sm-max) {
					margin-bottom: 0.6rem;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.price-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.4rem;
			@include bp(sm-max) {
				margin-bottom: 1.2rem;
			}
			&.total-row {
				font-family: $mediumFont;
				margin-bottom: 0;
				font-size: 1.6rem;
				line-height: 2rem;
			}
			.label {
				color: $darkGrey;
				strong {
					color: $black1;
					font-family: $mediumFont;
				}
			}
			.price {
				strong {
					color: $black1;
					font-family: $mediumFont;
				}
			}
		}

		.summary-btn {
			margin-top: 3.4rem;
			.orange-btn {
				width: 100%;
			}
		}

		.apply-coupon-block {
			.form-group {
				position: relative;
				margin-bottom: 0.3rem;
				.form-control {
					background: transparent;
					padding-right: 7rem;
					@include bp(sm-max) {
						padding-right: 6rem;
					}
				}
				.primary-link {
					position: absolute;
					right: 1.4rem;
					top: 0.9rem;
				}
			}
			.see-all-coupons {
				font-size: 1.6rem;
				font-family: $semiboldFont;
			}
		}

		.coupon-applied-block {
			position: relative;
			padding-left: 4.4rem;
			display: flex;
			justify-content: space-between;
			.icon {
				position: absolute;
				left: 0;
				top: 0;
			}
			.content {
				width: calc(100% - 4.6rem);
				h6 {
					margin-bottom: 0.2rem;
					color: $orange;
				}
			}
			.remove-cpn {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: #fff;
				height: 3.2rem;
				width: 3.2rem;
				border-radius: 50%;
				img {
					max-width: 1.9rem;
				}
			}
		}
	}
}
