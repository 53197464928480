.product-listing-page {
	padding: 2.6rem 0 12rem;
	@include bp(md-max) {
		padding: 2rem 0 9.5rem;
	}
	.prod-listing-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 3.1rem;
		@include bp(sm-max) {
			margin-bottom: 2.9rem;
		}
		.sorting {
			display: flex;
			align-items: center;
			@include bp(md-max) {
				display: none;
			}
			.sort-label {
				white-space: nowrap;
				position: relative;
				top: 0;
				font-family: $mediumFont;
				display: inline-block;
				margin-right: 0.4rem;
			}
			.custom-select {
				min-width: 14.2rem;
				.dk-selected {
					text-align: right;
					.rtl & {
						text-align: left;
					}
				}
			}
		}
	}
	.prod-listing-content {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;
		.filter-wrapper {
			width: 32.4rem;
			padding: 0 1.5rem;
			@extend .transition;
			@include bp(md-max) {
				width: 100%;
				padding: 0;
				position: fixed;
				z-index: 999;
				top: 0;
				bottom: 0;
				right: -100%;
				background: $white;
				.mob-filter-open & {
					right: 0;
				}
			}
		}
		.no-data-block {
			padding-top: 19.7rem;
			width: 31rem;
			@include bp(md-max) {
				padding-top: 7.6rem;
			}
		}
		.prod-listing-wrapper {
			width: calc(100% - 32.4rem);
			padding: 0 1.5rem;
			@include bp(md-max) {
				width: 100%;
			}
		}
	}
}

.prod-listing-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem -3.3rem;
	@include bp(lg-max) {
		margin: 0 -1rem -3.3rem;
	}
	@include bp(sm-max) {
		margin: 0 -1rem -2.3rem;
	}
	.prod-listing-col {
		width: 33.33%;
		padding: 0 1.5rem;
		margin-bottom: 3.3rem;
		@include bp(lg-max) {
			padding: 0 1rem;
		}
		@include bp(sm-max) {
			width: 50%;
			margin-bottom: 2.3rem;
		}
	}
}

.filter-box {
	border-radius: 1rem;
	border: 0.1rem solid $darkGrey2;
	font-size: 1.4rem;
	@include bp(md-max) {
		border-radius: 0;
		border: 0;
	}
	.filter-outer {
		padding: 1.5rem 1.8rem 2.7rem;
		border-bottom: 0.1rem solid $darkGrey2;
		box-shadow: 0 0.1rem 0.2rem 0 rgba($black, 0.1);
		@include bp(md-max) {
			border-bottom: 0;
			padding: 1.4rem 1.6rem;
		}
		&.filter-list-expanded {
			.filter-expand-link {
				.primary-link {
					span {
						&.more {
							display: none;
						}
						&.less {
							display: block;
						}
					}
				}
			}
			.filter-chips-wrapper {
				height: auto;
				overflow: inherit;
			}
		}
		.filter-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 2.3rem;
			@include bp(md-max) {
				margin-bottom: 0;
			}
			h5 {
				margin: 0;
				display: inline-flex;
				@include bp(md-max) {
					font-size: 1.8rem;
				}
				.mob-filter-close {
					display: none;
					height: 2.4rem;
					width: 2.4rem;
					align-items: center;
					justify-content: center;
					line-height: 0;
					margin-right: 1.2rem;
					cursor: pointer;
					.rtl & {
						margin-right: 0;
						margin-left: 1.2rem;
						img {
							@include rotate(180deg);
						}
					}
					@include bp(md-max) {
						display: inline-flex;
					}
				}
			}
			.primary-link {
				font-size: 1.6rem;
				line-height: 2.2rem;
				font-family: $regularFont;
				@include bp(md-max) {
					color: $black1;
				}
				&.desktop-clear {
					@include bp(md-max) {
						display: none;
					}
				}
				&.mobile-clear {
					display: none;
					@include bp(md-max) {
						display: block;
					}
				}
			}
		}
		.filter-expand-link {
			margin-top: 0.3rem;
			@include bp(md-max) {
				display: none;
			}
			.primary-link {
				font-family: $regularFont;
				font-size: 1.6rem;
				line-height: 2.4rem;
				span {
					&.less {
						display: none;
					}
				}
			}
		}
		.filter-chips-wrapper {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -0.8rem -0.8rem 0;
			height: 7rem;
			overflow: hidden;
			@include bp(md-max) {
				display: none;
			}
			.filter-chip {
				margin-right: 0.8rem;
				margin-bottom: 0.8rem;
				background: #f3f4f6;
				border-radius: 2rem;
				padding: 0.1rem 0.6rem 0.6rem;
				font-size: 1.4rem;
				line-height: 2rem;
				display: inline-flex;
				align-items: center;
				.remove {
					height: 1.5rem;
					width: 1.5rem;
					background-image: url("../images/close-black.svg");
					background-size: 0.7rem;
					background-repeat: no-repeat;
					background-position: center;
					position: relative;
					top: 0.3rem;
					margin-left: 0.4rem;
				}
			}
		}
	}
	.mob-filter-wrapper {
		@include bp(md-max) {
			display: flex;
			padding-top: 0.2rem;
			height: calc(100vh - 12.2rem);
			overflow: hidden;
			.mob-filter-trigger {
				background-color: $grey;
			}
		}
		.mob-filter-trigger {
			display: none;
			overflow: hidden;
			@include bp(md-max) {
				width: 40%;
				display: block;
			}
			.inner-links {
				height: 100%;
				overflow: hidden;
				margin-right: -2rem;
				.rtl & {
					margin-right: 0;
					margin-left: -2rem;
				}
			}
			ul {
				overflow: hidden auto;
				height: 100%;
				padding-right: 2rem;
				.rtl & {
					padding-right: 0;
					padding-left: 2rem;
				}
				a {
					display: block;
					padding: 1.2rem 3rem 1.6rem 1.6rem;
					font-size: 1.4rem;
					line-height: 2rem;
					color: $black1;
					position: relative;
					.rtl & {
						padding: 1.2rem 1.6rem 1.6rem 3rem;
					}
					&.active {
						font-family: $mediumFont;
						color: $orange;
						background-color: $white;
					}
					&.filter-applied {
						&::after {
							display: block;
						}
					}
					&::after {
						content: "";
						position: absolute;
						right: 1.6rem;
						top: 2rem;
						height: 0.8rem;
						width: 0.8rem;
						border-radius: 50%;
						background-color: $orange;
						@extend .transition;
						display: none;
						.rtl & {
							right: auto;
							left: 1.6rem;
						}
					}
				}
			}
		}
		.filter-accordion-wrapper {
			@include bp(md-max) {
				width: 60%;
				height: 100%;
			}
		}
	}
	.mob-applied-btn {
		display: none;
		position: absolute;
		bottom: 0;
		z-index: 99;
		background: $white;
		left: 0;
		right: 0;
		padding: 1.5rem 1.6rem !important;
		box-shadow:
			0 -2rem 2.4rem -0.4rem rgba($black, 0.07),
			0 -0.8rem 0.8rem -0.4rem rgba($black, 0.04);
		@include bp(md-max) {
			display: block;
		}
		.orange-btn {
			font-size: 1.4rem;
			width: 100%;
		}
	}
}

.filter-accordion-wrapper {
	.filter-outer-accordion {
		border-bottom: 0.1rem solid $darkGrey2;
		@include bp(md-max) {
			border-bottom: 0;
			display: none;
			height: 100%;
		}
		&:last-child {
			border-bottom: 0;
		}
		&.filter-expanded {
			@include bp(md-max) {
				display: block;
			}
			.filter-heading {
				&:after {
					@include rotate(180deg);
				}
			}
		}
		.filter-heading {
			position: relative;
			padding: 1.8rem 3.5rem 2.2rem 1.8rem;
			cursor: pointer;
			font-size: 1.6rem;
			line-height: 2.2rem;
			font-family: $mediumFont;
			.rtl & {
				padding: 1.8rem 1.8rem 2.2rem 3.5rem;
			}
			@include bp(md-max) {
				display: none;
			}
			&.filter-applied {
				.title {
					&::after {
						display: block;
					}
				}
			}
			.title {
				position: relative;
				&::after {
					content: "";
					position: absolute;
					right: -1.8rem;
					top: 1.4rem;
					height: 0.8rem;
					width: 0.8rem;
					border-radius: 50%;
					background-color: $orange;
					@extend .transition;
					display: none;
					.rtl & {
						right: auto;
						left: -1.8rem;
					}
				}
			}
			&:after {
				content: "";
				position: absolute;
				right: 1.8rem;
				top: calc(50% - 0.35rem);
				width: 1.2rem;
				height: 0.7rem;
				background-image: url("../images/down-arrow.svg");
				background-size: 1.2rem;
				background-repeat: no-repeat;
				background-position: center;
				@extend .transition;
				.rtl & {
					right: auto;
					left: 1.8rem;
				}
			}
		}
		.filter-body {
			padding: 0 1.8rem 2rem;
			display: none;
			@include bp(md-max) {
				display: block !important;
				padding: 1.3rem 1.6rem;
				height: 100%;
				overflow: hidden auto;
			}
			.clear-all-link {
				display: flex;
				align-items: center;
				line-height: 2rem;
				color: $darkGrey;
				margin-bottom: 1.1rem;
				@include bp(md-max) {
					display: none;
				}
				.icon {
					display: inline-flex;
					height: 2rem;
					width: 2rem;
					background: $grey;
					background-image: url("../images/close-black.svg");
					background-size: 1rem;
					background-position: center;
					background-repeat: no-repeat;
				}
				span {
					width: calc(100% - 2rem);
					padding-left: 0.8rem;
					@extend .truncated-text;
					position: relative;
					top: -0.2rem;
					.rtl & {
						padding-left: 0;
						padding-right: 0.8rem;
					}
				}
			}
			.view-all-link {
				display: flex;
				align-items: center;
				margin-top: 1.6rem;
				color: $black1;
				@include bp(md-max) {
					display: none;
				}
				&.all-visible {
					span {
						&.view-all {
							display: none;
						}
						&.view-less {
							display: block;
						}
					}
				}
				.icon {
					display: inline-flex;
					background-image: url("../images/view-all.svg");
					background-size: 1.3rem;
					background-repeat: no-repeat;
					background-position: center;
					width: 1.3rem;
					height: 1.7rem;
					position: relative;
					top: 0.2rem;
				}
				span {
					width: calc(100% - 1.3rem);
					padding-left: 0.6rem;
					.rtl & {
						padding-left: 0;
						padding-right: 0.6rem;
					}
					&.view-less {
						display: none;
					}
				}
			}
			.hidden-filters {
				margin-top: 1.5rem;
				display: none;
				@include bp(md-max) {
					margin-top: 1.3rem;
					display: block;
				}
				&.all-filters-visible {
					display: block;
				}
			}
			.radio-wrapper {
				.radio-outer {
					margin-bottom: 1.2rem;
					@include bp(md-max) {
						margin-bottom: 1.7rem;
					}
					&:last-child {
						margin-bottom: 0;
					}
					label {
						@include bp(md-max) {
							line-height: 2rem;
						}
						&::before,
						&:after {
							@include bp(md-max) {
								top: calc(50% + 0.3rem);
								transform: translateY(-50%);
							}
						}
					}
				}
			}
			.custom-secondary-checkbox {
				label {
					@include bp(md-max) {
						line-height: 2rem;
					}
					&::before,
					&:after {
						@include bp(md-max) {
							top: calc(50% + 0.3rem);
							transform: translateY(-50%);
						}
					}
				}
			}
			.sub-accordion-wrapper {
				margin: -2rem 0;
				@include bp(md-max) {
					margin: -1.3rem -1.6rem;
				}
				.sub-accordion-outer {
					@include bp(md-max) {
						padding: 0 1.6rem;
					}
				}
			}
		}
	}
}

.sub-accordion-wrapper {
	.sub-accordion-outer {
		border-bottom: 0.1rem solid $darkGrey2;
		&:last-child {
			border-bottom: 0;
		}
		&.sub-filter-expanded {
			.sub-accordion-title {
				.trigger {
					@include rotate(180deg);
				}
			}
		}
		.sub-accordion-title {
			padding: 1.5rem 0;
			display: flex;
			justify-content: space-between;
			position: relative;
			@include bp(md-max) {
				padding: 1.3rem 0;
			}
			.custom-secondary-checkbox {
				font-family: $mediumFont;
				width: calc(100% - 2rem);
				label {
					width: 100%;
					@extend .truncated-text;
					&:before,
					&:after {
						@include bp(md-max) {
							top: calc(50% - 0.1rem);
						}
					}
				}
			}
			.trigger {
				position: absolute;
				right: -0.3rem;
				top: calc(50% - 1rem);
				background-image: url(../images/down-arrow.svg);
				background-size: 1.2rem;
				background-repeat: no-repeat;
				background-position: center;
				display: block;
				height: 2rem;
				width: 2rem;
				cursor: pointer;
				@extend .transition;
				.rtl & {
					right: auto;
					left: -0.3rem;
				}
			}
		}
		.sub-accordion-body {
			padding-bottom: 2rem;
			display: none;
		}
	}
}

.filter-sort-btns {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background: $white;
	padding: 1.1rem 1.6rem;
	box-shadow:
		0rem -0.8rem 0.8rem -0.4rem rgba($black, 0.04),
		0rem -2rem 2.4rem -0.4rem rgba($black, 0.07);
	display: none;
	@include bp(md-max) {
		display: block;
	}
	ul {
		display: flex;
		margin: 0 -1rem;
		li {
			width: 50%;
			padding: 0 1rem;
			border-right: 0.1rem solid $darkGrey2;
			&:last-child {
				background-repeat: 0;
			}
			.mob-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 4.8rem;
				font-size: 1.6rem;
				line-height: 2.4rem;
				font-family: $mediumFont;
				color: $black1;
				.icon {
					line-height: 0;
					height: 2.1rem;
					width: 2.1rem;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin-right: 0.3rem;
					.rtl & {
						margin-right: 0;
						margin-left: 0.3rem;
					}
				}
			}
		}
	}
}

.product-detail-page {
	padding: 2.6rem 0 12rem;
	@include bp(md-max) {
		padding: 2rem 0 9rem;
	}
	@include bp(sm-max) {
		padding: 2rem 0 3rem;
	}
	.product-detail-wrapper {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin: 3rem -1.5rem 0;
		&.reviews-page {
			.detail-left {
				width: 33.4%;
				@include bp(lg-max) {
					width: 40%;
				}
				@include bp(smd-max) {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}
				.product-card {
					margin-bottom: 2.7rem;
					@include bp(smd-max) {
						width: 18rem;
						margin: 0;
					}
					@include bp(sm-max) {
						width: 16.5rem;
					}
					@include bp(xs-max) {
						width: 100%;
						margin-bottom: 2.7rem;
					}
					.prod-thumb {
						margin: 0;
					}
				}
				.reivew-prod-info {
					@include bp(smd-max) {
						width: calc(100% - 18rem);
						padding-left: 1.6rem;
						.rtl & {
							padding-left: 0;
							padding-right: 1.6rem;
						}
					}
					@include bp(sm-max) {
						width: calc(100% - 16.5rem);
					}
					@include bp(xs-max) {
						width: 100%;
						padding: 0 !important;
					}
				}
				.prod-btns {
					margin-top: 3.1rem;
					position: static;
					box-shadow: none;
					padding: 0;
					background: transparent;
					@include bp(sm-max) {
						margin-top: 2rem;
					}
					.orange-btn {
						width: calc(50% - 0.7rem);
						@include bp(xxs-max) {
							padding: 0;
						}
					}
				}
			}
			.detail-right {
				width: 66.6%;
				@include bp(lg-max) {
					width: 60%;
				}
				@include bp(smd-max) {
					width: 100%;
				}
			}
			.review-wrapper {
				@include bp(smd-max) {
					margin: 3rem -1.6rem 0;
					border-top: 0.1rem solid $darkGrey2;
					padding-top: 3rem;
				}
			}
		}
		.detail-left {
			width: 50%;
			padding: 0 1.5rem;
			@include bp(md-max) {
				width: 100%;
			}
			&.is-sticky {
				position: sticky;
				top: 14rem;
				@media (max-height: 800px) {
					position: static;
				}
				@include bp(lg-max) {
					position: static;
				}
			}
			.prod-btns {
				display: flex;
				flex-wrap: wrap;
				margin-top: 4rem;
				justify-content: space-between;
				&.full-width {
					.orange-btn {
						@include bp(md-max) {
							width: 100%;
						}
					}
				}
				@include bp(md-max) {
					margin: 0;
					position: fixed;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 5;
					padding: 1.5rem 1.6rem;
					background: $white;
					box-shadow:
						0 -0.8rem 0.8rem -0.4rem rgba($black, 0.04),
						0 -2rem 2.4rem -0.4rem rgba($black, 0.07);
				}
				.orange-btn {
					width: calc(50% - 1.5rem);
					display: flex;
					justify-content: center;
					align-items: flex-start;
					@include bp(sm-max) {
						width: calc(50% - 1rem);
					}
					.icon {
						line-height: 0;
						margin: 0.7rem 0.8rem 0 0;
						height: 2.4rem;
						width: 2.4rem;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						.rtl & {
							padding-right: 0;
							padding-left: 0.8rem;
						}
					}
				}
			}
		}
		.detail-right {
			width: 50%;
			padding: 0 1.5rem;
			@include bp(md-max) {
				width: 100%;
			}
		}
		.product-main-slider {
			border-radius: 1rem;
			margin-bottom: 3rem;
			border: 0.1rem solid $darkGrey2;
			@include bp(md-max) {
				margin-bottom: 1.1rem;
			}
			.favourite {
				position: absolute;
				right: 1.6rem;
				top: 1.6rem;
				line-height: 0;
				background: #fff;
				border-radius: 50%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				height: 4.8rem;
				width: 4.8rem;
				z-index: 2;
				.rtl & {
					right: auto;
					left: 1.6rem;
				}
				@include bp(smd-max) {
					height: 3.4rem;
					width: 3.4rem;
					top: 1rem;
					right: 0.5rem;
					.rtl & {
						left: 0.5rem;
					}
				}
				img {
					width: 2.8rem;
					@include bp(smd-max) {
						width: 2rem;
					}
				}
			}
			.swiper-slide {
				position: relative;
				padding-bottom: 66.8%;
				border-radius: 1rem;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				@include bp(md-max) {
					padding-bottom: 50%;
				}
				@include bp(xs-max) {
					padding-bottom: 79.8%;
				}
				.prod-img,
				.prod-video {
					max-height: 100%;
					max-width: 100%;
					position: absolute;
					left: 50%;
					top: 50%;
					@include translate(-50%, -50%);
				}
				.play-btn {
					position: absolute;
					left: 50%;
					top: 50%;
					@include translate(-50%, -50%);
					line-height: 0;
					cursor: pointer;
					z-index: 1;
					width: 7rem;
				}
			}
		}
		.product-thumb-slider {
			@include bp(md-max) {
				display: none;
			}
			.swiper-slide {
				border: 0.1rem solid $darkGrey2;
				height: 6.6rem;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				cursor: pointer;
				width: 10rem;
				.thumb-img {
					max-height: 100%;
				}
				.play-btn {
					position: absolute;
					left: 50%;
					top: 50%;
					@include translate(-50%, -50%);
					line-height: 0;
					cursor: pointer;
					z-index: 1;
					width: 2.4rem;
				}
			}
		}
		.prod-info-block {
			padding-bottom: 3.3rem;
			margin-bottom: 2.5rem;
			border-bottom: 0.1rem solid $darkGrey2;
			&.secondary {
				padding-bottom: 0;
				border-bottom: 0;
				margin-bottom: 0;
				h2 {
					font-size: 2rem;
					line-height: 3.2rem;
					margin-bottom: 0.9rem;
					padding-right: 0;
					.rtl & {
						padding-left: 0;
					}
				}
				.price {
					font-size: 2rem;
					line-height: 2.6rem;
					.discounted {
						top: 0.1rem;
					}
				}
			}
			h6 {
				margin-bottom: 1rem;
				@include bp(sm-max) {
					margin-bottom: 1.5rem;
				}
			}
			.desc {
				color: $darkGrey;
				@include bp(sm-max) {
					line-height: 2.2rem;
				}
			}
		}
		.prod-main-info {
			position: relative;
			.share {
				position: absolute;
				right: 0;
				top: 0;
				height: 4rem;
				width: 4rem;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: $grey;
				z-index: 1;
				.rtl & {
					right: auto;
					left: 0;
				}
				@include bp(sm-max) {
					top: 0.9rem;
				}
			}
			.off-percentage {
				display: block;
				color: $darkGrey;
				font-size: 1.8rem;
				line-height: 2.6rem;
				font-family: $mediumFont;
				margin-bottom: 0.9rem;
				@include bp(smd-max) {
					font-size: 1.6rem;
					line-height: 2.4rem;
				}
				@include bp(sm-max) {
					font-size: 1.4rem;
					line-height: 2rem;
					margin-bottom: 0.4rem;
				}
			}
			h2 {
				margin-bottom: 1.4rem;
				@include bp(sm-max) {
					font-size: 1.8rem;
					line-height: 2.6rem;
					padding-right: 5rem;
					margin-bottom: 0.6rem;
					.rtl & {
						padding-right: 0;
						padding-left: 5rem;
					}
				}
			}
			.price {
				@extend .h2;
				font-family: $semiboldFont;
				color: $orange;
				display: flex;
				align-items: center;
				margin-bottom: 1.7rem;
				padding: 0 !important;
				@include bp(sm-max) {
					font-size: 1.8rem;
					line-height: 2.6rem;
					margin-bottom: 0.6rem;
				}
				.discounted {
					font-size: 1.8rem;
					line-height: 2.6rem;
					color: $darkGrey;
					margin-left: 1rem;
					font-family: $regularFont;
					position: relative;
					position: relative;
					top: 0.3rem;
					.rtl & {
						margin-left: 0;
						margin-right: 1rem;
					}
					@include bp(sm-max) {
						font-size: 1.4rem;
						line-height: 2.4rem;
						margin-left: 0.8rem;
						top: 0.1rem;
						.rtl & {
							margin-left: 0;
							margin-right: 0.8rem;
						}
					}
					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0.9rem;
						height: 0.1rem;
						background-color: $darkGrey;
						display: block;
					}
				}
			}
			.prod-rating {
				display: flex;
				align-items: center;
				font-size: 1.8rem;
				line-height: 2.6rem;
				color: $darkGrey;
				@include bp(sm-max) {
					font-size: 1.4rem;
					line-height: 2.4rem;
				}
				.rating {
					display: flex;
					align-items: center;
					font-family: $mediumFont;
					color: $black1;
					.icon {
						line-height: 0;
						margin-right: 0.6rem;
						.rtl & {
							margin-right: 0;
							margin-left: 0.6rem;
						}
						@include bp(sm-max) {
							width: 1.6rem;
						}
					}
				}
				.review-count {
					margin-left: 0.5rem;
					margin-right: 1.9rem;
					position: relative;
					.rtl & {
						margin-right: 0.5rem;
						margin-left: 1.9rem;
					}
					&:after {
						content: "";
						height: 0.5rem;
						width: 0.5rem;
						background: $darkGrey;
						border-radius: 50%;
						display: block;
						position: absolute;
						right: -1.2rem;
						top: 1.4rem;
						.rtl & {
							right: auto;
							left: -1.2rem;
						}
						@include bp(sm-max) {
							top: 1.2rem;
						}
					}
				}
			}
		}
	}
}

.customized-design-wrapper {
	&.open {
		.design-title {
			&:after {
				@include rotate(180deg);
			}
		}
	}
	.design-title {
		position: relative;
		font-size: 1.6rem;
		line-height: 2.4rem;
		font-family: $mediumFont;
		cursor: pointer;
		padding-right: 3rem;
		.rtl & {
			padding-right: 0;
			padding-left: 3rem;
		}
		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 0.3rem;
			width: 2.4rem;
			height: 2.4rem;
			z-index: 1;
			background-image: url("../images/down-arrow.svg");
			background-size: 1.2rem;
			background-repeat: no-repeat;
			background-position: 50%;
			cursor: pointer;
			@extend .transition;
			.rtl & {
				right: auto;
				left: 0;
			}
		}
	}
	.design-body {
		padding: 0.8rem 0 0;
		display: none;
		.design-row {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border-bottom: 0.1rem solid $darkGrey2;
			padding: 2.2rem 0;
			@include bp(sm-max) {
				padding: 1.3rem 0 1.7rem;
			}
			&:last-child {
				border-bottom: 0;
				padding-bottom: 0;
				@include bp(sm-max) {
					margin-bottom: -1.4rem;
				}
			}
			.title {
				color: $darkGrey;
				width: 10rem;
				@include bp(sm-max) {
					width: 100%;
					margin-bottom: 1.1rem;
					display: flex;
					justify-content: space-between;
				}
			}
			.design-selection {
				width: calc(100% - 10rem);
				padding-left: 1.4rem;
				.rtl & {
					padding-left: 0;
					padding-right: 1.4rem;
					@include bp(sm-max) {
						padding-right: 0;
					}
				}
				@include bp(sm-max) {
					width: 100%;
					padding-left: 0;
				}
				.custom-checkbox-wrapper {
					margin-bottom: -1.2rem;
					.checkout-outer {
						margin-bottom: 1.2rem;
					}
				}
			}
		}
	}
}

.specs-wrapper {
	margin-bottom: 3rem;
	@include bp(md-max) {
		margin: 0 -1.6rem 3.2rem;
	}
	&:last-child {
		margin-bottom: 0;
	}
	.specs-outer {
		.specs-body {
			padding: 0 1.6rem;
			.review-wrapper {
				margin-top: 1.4rem;
			}
			.hidden-specs {
				display: none;
			}
			.view-more {
				display: inline-flex;
				font-family: "AbdoMasterMedium";
				color: $orange;
				&.more-specs-link {
					margin-top: 1.1rem;
				}
				&.specs-visible {
					.more {
						display: none;
					}
					.less {
						display: inline-block;
					}
				}
				.less {
					display: none;
				}
				.icon {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					height: 2.4rem;
					width: 2.4rem;
					margin-left: 0.2rem;
					line-height: 0;
					position: relative;
					top: 0.2rem;
					.rtl & {
						margin-right: 0;
						margin-left: 0.2rem;
					}
					img {
						@include rotate(-90deg);
						.rtl & {
							@include rotate(90deg);
						}
					}
				}
			}
		}
	}
	.specs-title {
		padding: 1.2rem 1.6rem 1.6rem;
		background: $grey;
		font-family: $mediumFont;
		margin-bottom: 0.2rem;
		font-size: 1.6rem;
		@include bp(sm-max) {
			line-height: 2rem;
			margin-bottom: 0.7rem;
		}
	}
	.specs-row {
		display: flex;
		justify-content: space-between;
		border-bottom: 0.1rem solid $darkGrey2;
		padding: 1rem 0 1.7rem;
		@include bp(sm-max) {
			padding: 0.9rem 0 1.4rem;
		}
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
		strong {
			font-family: $mediumFont;
			color: $black1 !important;
			font-size: 1.6rem;
		}
		.label {
			color: $darkGrey;
			width: 50%;
			@include bp(sm-max) {
				width: 50%;
			}
		}
		.value {
			text-align: right;
			width: 50%;
			.rtl & {
				text-align: left;
			}
			@include bp(sm-max) {
				width: 50%;
			}
			&.gemstone-type {
				&.open {
					.gemstone-more-less {
						.more {
							display: none;
						}
						.less {
							display: inline-block;
						}
					}
				}
				.type-list {
					display: block;
					.more-types {
						display: none;
					}
				}
				.gemstone-more-less {
					.less {
						display: none;
					}
				}
			}
			a {
				color: $orange;
			}
		}
	}
}

.review-wrapper {
	.review-outer {
		padding-bottom: 1.9rem;
		margin-bottom: 1.2rem;
		border-bottom: 0.1rem solid $darkGrey2;
		&.secondary {
			padding: 1.7rem 2rem 2.2rem;
			margin-bottom: 0;
			@include bp(smd-max) {
				padding: 1.7rem 1.6rem 2.2rem;
			}
		}
		&.is-highlighted {
			background-color: $grey;
		}
		.review-text {
			margin-bottom: 1.1rem;
			@include bp(sm-max) {
				line-height: 2.2rem;
			}
		}
		.user-info {
			display: flex;
			align-items: center;
			color: $darkGrey;
			margin-bottom: 0.3rem;
			@include bp(sm-max) {
				margin-bottom: 0.6rem;
			}
			.username {
				font-family: $mediumFont;
			}
			.links {
				display: flex;
				align-items: center;
				li {
					margin-left: 1.9rem;
					position: relative;
					.rtl & {
						margin-left: 0;
						margin-right: 1.9rem;
					}
					&::before {
						content: "";
						height: 0.5rem;
						width: 0.5rem;
						background: $darkGrey;
						border-radius: 50%;
						display: block;
						position: absolute;
						left: -1.1rem;
						bottom: 0.6rem;
						.rtl & {
							left: auto;
							right: -1.1rem;
						}
						@include bp(sm-max) {
							bottom: 0.5rem;
						}
					}
					a {
						color: $darkGrey;
						&.orange-link {
							color: $orange;
						}
					}
				}
			}
		}
		.rating-date {
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: $darkGrey;
			.ratings {
				display: flex;
				span {
					line-height: 0;
					margin-right: 0.8rem;
					.rtl & {
						margin-right: 0;
						margin-left: 0.8rem;
					}
					&:last-child {
						margin: 0;
					}
					img {
						width: 1.5rem;
					}
				}
			}
		}
	}
}

.qty-block {
	display: flex;
	flex-wrap: wrap;
	@include bp(xs-max) {
		justify-content: space-between;
	}
	&:not(.single-field) {
		.qty-outer {
			.qty-counter {
				@include bp(xs-max) {
					width: 100%;
				}
			}
		}
	}
	&.single-field {
		.qty-outer {
			@include bp(md-max) {
				width: 100%;
			}
			.form-group {
				@include bp(md-max) {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
				label {
					@include bp(md-max) {
						margin: 0;
					}
				}
			}
		}
	}
	.qty-outer {
		width: 15.4rem;
		max-width: 100%;
		margin-right: 3rem;
		.rtl & {
			margin-right: 0;
			margin-left: 3rem;
		}
		@include bp(xs-max) {
			margin-right: 0;
			width: calc(50% - 1rem);
			.rtl & {
				margin-left: 0;
			}
		}
		&:last-child {
			margin: 0;
		}
		.form-group {
			margin: 0;
			> label {
				margin-bottom: 1.3rem;
				@include bp(sm-max) {
					font-size: 1.4rem;
					margin-bottom: 0.6rem;
				}
			}
		}
	}
}
