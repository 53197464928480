$black: #000000;
$white: #ffffff;
$black1: #172226;
$black2: #464646;
$orange: #e38871;
$lightOrange: #e4a494;
$lightOrange1: #ffefeb;
$lightOrange2: #ffe8e2;
$grey: #f5f5f5;
$lightGrey: #e1e1e1;
$lightGrey1: #f2f2f2;
$darkGrey: #8a8a8a;
$darkGrey1: #818181;
$darkGrey2: #c2c2c2;
$lightRed: #ffe8e8;
$warning: #fff7e7;
$green: #efffe7;
$red: #ff4d4f;
