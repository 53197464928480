.hero-block {
	padding: 5rem 0 0;
	@include bp(sm-max) {
		padding: 0;
		margin: 0 -1.6rem;
	}
	.slider-container {
		.hero-slider {
			.slide-outer {
				padding-bottom: 55.6%;
				position: relative;
				z-index: 0;
				@extend .bg-props;
				&::before {
					content: "";
					@extend .absolute-pos;
					background-color: rgba($black1, 0.2);
					@include bp(sm-max) {
						background-color: rgba($black1, 0.4);
					}
				}
				.hero-content {
					position: absolute;
					top: 50%;
					@include translateY(-50%);
					max-width: 94.6rem;
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 2;
					color: $white;
					@include bp(lg-max) {
						max-width: inherit;
						padding: 0 4rem;
					}
					@include bp(smd-max) {
						padding: 0 2rem;
					}
					h1 {
						margin-bottom: 1rem;
						@include bp(smd-max) {
							margin-bottom: 0.5rem;
						}
					}
					p {
						font-size: 1.8rem;
						line-height: 2.8rem;
						margin-bottom: 1.8rem;
						font-family: $mediumFont;
						@include bp(smd-max) {
							font-size: 1.6rem;
							line-height: 2.6rem;
						}
						@include bp(sm-max) {
							font-size: 1.4rem;
							line-height: 2rem;
						}
					}
					.orange-btn {
						width: 19.7rem;
						font-size: 1.6rem;
						@include bp(sm-max) {
							width: 12.7rem;
							font-size: 1.4rem;
						}
					}
				}
			}
		}
		.swiper-button-next {
			right: 3rem;
			.rtl & {
				right: auto;
				left: 3rem;
			}
			@include bp(lg-max) {
				display: none;
			}
		}
		.swiper-button-prev {
			left: 3rem;
			.rtl & {
				right: 3rem;
				left: auto;
			}
			@include bp(lg-max) {
				display: none;
			}
		}
	}
}

.home-prod-type {
	padding: 12rem 0 0;
	@include bp(lg-max) {
		padding: 8rem 0 0;
	}
	@include bp(smd-max) {
		padding: 6rem 0 0;
	}
	@include bp(sm-max) {
		padding: 3rem 0 0;
	}
	.prod-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem;
		@include bp(sm-max) {
			margin: 0 -0.6rem;
		}
		li {
			width: 33.33%;
			padding: 0 1.5rem;
			@include bp(sm-max) {
				padding: 0 0.6rem;
			}
			.prod-card {
				background: $grey;
				border-radius: 0.6rem;
				height: 11rem;
				display: flex;
				align-items: center;
				justify-content: center;
				@extend .h5;
				margin: 0;
				@include bp(sm-max) {
					height: 10rem;
					flex-direction: column;
				}
				&:hover {
					background: darken($grey, 5%);
				}
				.icon {
					line-height: 0;
					width: 5rem;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin-right: 2rem;
					@include bp(sm-max) {
						margin-right: 0;
						margin-bottom: 1rem;
						height: 3.4rem;
					}
					img {
						@include bp(sm-max) {
							max-height: 3.4rem;
						}
					}
				}
				span {
					position: relative;
					top: -0.4rem;
				}
			}
		}
	}
}

.home-exclusive-jewellery {
	padding: 11.3rem 0 12.4rem;
	@include bp(lg-max) {
		padding: 8rem 0 10rem;
	}
	@include bp(smd-max) {
		padding: 6rem 0 8rem;
	}
	@include bp(sm-max) {
		padding: 2.5rem 0 3.5rem;
	}
	&.is-trusted-seller {
		@include bp(sm-max) {
			padding: 0;
		}
		.slider-container {
			@include bp(sm-max) {
				margin: 0 -1.6rem;
			}
		}
	}
	.jewellery-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5rem -2.3rem;
		width: auto;
		@include bp(sm-max) {
			margin: 0 -0.6rem -2.3rem;
		}
		&.swiper-wrapper {
			@include bp(sm-max) {
				flex-wrap: nowrap;
				margin: 0;
			}
			.jewellery-col {
				width: 33.33%;
				@include bp(sm-max) {
					padding: 0;
					width: 100%;
					margin: 0;
				}
				.jewellery-box {
					.thumb {
						padding-bottom: 100%;
						margin: 0;
						border-radius: 0.6rem;
						@include bp(sm-max) {
							border-radius: 0;
						}
					}
				}
			}
		}
		.jewellery-col {
			width: 25%;
			padding: 0 1.5rem;
			margin-bottom: 2.3rem;
			@include bp(sm-max) {
				padding: 0 0.6rem;
				width: 33.33%;
			}
			.jewellery-box {
				display: block;
				text-align: center;
				color: $black1;
				&:hover {
					.thumb {
						img {
							@include scale(1.05);
						}
					}
				}
				.thumb {
					position: relative;
					padding-bottom: 121.1%;
					border-radius: 1rem;
					overflow: hidden;
					margin-bottom: 1.2rem;
					@include bp(sm-max) {
						margin-bottom: 0.3rem;
					}
					img {
						@extend .absolute-pos;
						@extend .cover-fit;
						@extend .transition;
					}
				}
				h5 {
					margin: 0;
				}
			}
		}
	}
}

.home-grey-block {
	padding: 6.3rem 0 7.4rem;
	background-color: $grey;
	@include bp(lg-max) {
		padding: 6rem 0 7rem;
	}
	@include bp(sm-max) {
		padding: 2.7rem 0 3.1rem;
	}
}

.product-list-slider {
	.swiper-slide {
		width: 29.4rem;
		@include bp(lg-max) {
			width: 25rem;
		}
		@include bp(smd-max) {
			width: 20rem;
		}
		@include bp(sm-max) {
			width: 15.4rem;
		}
	}
}

.product-list-control {
	.swiper-button-next,
	.swiper-button-prev {
		top: 42%;
		@include bp(md-max) {
			display: none;
		}
	}
	.swiper-button-prev {
		left: -3rem;
		.rtl & {
			left: auto;
			right: -3rem;
		}
		@include bp(xlg-max) {
			left: 0;
		}
	}
	.swiper-button-next {
		right: -3rem;
		.rtl & {
			left: -3rem;
			right: auto;
		}
		@include bp(xlg-max) {
			right: 0;
		}
	}
}

.virtual-try-section {
	padding: 12rem 0;
	@include bp(lg-max) {
		padding: 8rem 0;
	}
	@include bp(smd-max) {
		padding: 6rem 0;
	}
	@include bp(sm-max) {
		padding: 0;
	}
	.virtual-try-wrapper {
		background-color: $black1;
		padding: 5rem 1.6rem 0;
		color: $white;
		@include bp(sm-max) {
			margin: 0 -1.6rem;
			padding: 0.8rem 1.6rem 0;
		}
		.content-outer {
			display: flex;
			justify-content: space-between;
			max-width: 41rem;
			margin: 0 auto;
			.inner-content {
				max-width: 22.3rem;
				padding-top: 3.3rem;
				@include bp(sm-max) {
					max-width: 18rem;
					padding-top: 3.6rem;
				}
				h2 {
					text-transform: uppercase;
					font-size: 2.6rem;
					line-height: 3.6rem;
					margin-bottom: 2.1rem;
					@include bp(lg-max) {
						font-size: 2.4rem;
						line-height: 3.2rem;
					}
					@include bp(smd-max) {
						font-size: 2.2rem;
						line-height: 3rem;
					}
					@include bp(sm-max) {
						font-size: 2rem;
						line-height: 2.8rem;
					}
				}
				.orange-btn {
					width: 15.7rem;
					@include bp(sm-max) {
						width: 12.7rem;
					}
				}
			}
			.img-wrapper {
				@include bp(sm-max) {
					max-width: 10.8rem;
					margin-right: 1.4rem;
					.rtl & {
						margin-right: 0;
						margin-left: 1.4rem;
					}
				}
			}
		}
	}
}

.category-page {
	padding: 2.6rem 0 12rem;
	@include bp(sm-max) {
		padding: 2rem 0 8.5rem;
	}
	.breadcrumb {
		margin-bottom: 3.1rem;
		@include bp(sm-max) {
			margin-bottom: 2.9rem;
		}
	}
}

.category-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1.5rem;
	@include bp(smd-max) {
		margin: 0 -1rem;
	}
	&.subcategory-row {
		margin-bottom: -4rem;
		@include bp(smd-max) {
			margin-bottom: -3rem;
		}
		@include bp(sm-max) {
			margin: 0 -0.7rem -2.3rem;
		}
		.category-col {
			margin-bottom: 4rem;
			@include bp(smd-max) {
				margin-bottom: 3rem;
			}
			@include bp(sm-max) {
				width: 33.33%;
				padding: 0 0.7rem;
				margin-bottom: 2.3rem;
			}
			@include bp(xxs-max) {
				width: 50%;
			}
		}
	}
	.category-col {
		width: 25%;
		padding: 0 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include bp(smd-max) {
			padding: 0 1rem;
			width: 33.33%;
		}
		@include bp(sm-max) {
			padding: 0 1rem;
			width: 50%;
		}
		&:nth-child(even) {
			flex-direction: column-reverse;
		}
		.product-card {
			margin-bottom: 4.2rem;
			@include bp(sm-max) {
				margin-bottom: 2.3rem;
			}
			&:last-child {
				&:first-child {
					margin-bottom: auto;
				}
			}
		}
	}
}
