@font-face {
	font-family: "AbdoMasterBook";
	src: url("../fonts/AbdoMasterBook.eot");
	src: url("../fonts/AbdoMasterBook.eot") format("embedded-opentype"),
		url("../fonts/AbdoMasterBook.woff2") format("woff2"), url("../fonts/AbdoMasterBook.woff") format("woff"),
		url("../fonts/AbdoMasterBook.ttf") format("truetype"),
		url("../fonts/AbdoMasterBook.svg#AbdoMasterBook") format("svg");
}

@font-face {
	font-family: "AbdoMasterMedium";
	src: url("../fonts/AbdoMasterMedium.eot");
	src: url("../fonts/AbdoMasterMedium.eot") format("embedded-opentype"),
		url("../fonts/AbdoMasterMedium.woff2") format("woff2"), url("../fonts/AbdoMasterMedium.woff") format("woff"),
		url("../fonts/AbdoMasterMedium.ttf") format("truetype"),
		url("../fonts/AbdoMasterMedium.svg#AbdoMasterMedium") format("svg");
}

@font-face {
	font-family: "AbdoMasterSemiBold";
	src: url("../fonts/AbdoMasterSemiBold.eot");
	src: url("../fonts/AbdoMasterSemiBold.eot") format("embedded-opentype"),
		url("../fonts/AbdoMasterSemiBold.woff2") format("woff2"), url("../fonts/AbdoMasterSemiBold.woff") format("woff"),
		url("../fonts/AbdoMasterSemiBold.ttf") format("truetype"),
		url("../fonts/AbdoMasterSemiBold.svg#AbdoMasterSemiBold") format("svg");
}

$regularFont: "AbdoMasterBook", sans-serif;
$mediumFont: "AbdoMasterMedium", sans-serif;
$semiboldFont: "AbdoMasterSemiBold", sans-serif;
