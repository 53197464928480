.form-control {
	padding: 0.4rem 1.4rem 1rem;
	border: 0.1rem solid $darkGrey2;
	border-radius: 0.4rem;
	color: $black1;
	font-size: 1.6rem;
	font-family: $mediumFont;
	@extend .transition;
	box-shadow: none;
	height: 4.4rem;
	@extend .truncated-text;
	@include bp(sm-max) {
		height: 4rem;
	}
	&:focus {
		color: $black1;
		box-shadow: none;
		background-color: transparent;
		border-color: $black1;
	}
	&:disabled,
	&.disabled {
		background-color: $grey;
		cursor: not-allowed;
	}
	@include placeholder {
		font-size: 1.6rem !important;
		font-family: $mediumFont !important;
		opacity: 1 !important;
		color: $darkGrey;
	}
	&.file-upload {
		display: flex;
		align-items: center;
		color: $darkGrey;
		position: relative;
		.icon {
			width: 2.6rem;
			line-height: 0;
			position: relative;
			top: 0.3rem;
		}
		span {
			width: calc(100% - 2.6rem);
			padding-left: 1rem;
			.rtl & {
				padding-left: 0;
				padding-right: 1rem;
			}
			@include bp(sm-max) {
				padding: 0.5rem;
			}
		}
	}
}

textarea.form-control {
	min-height: 13.2rem;
	white-space: inherit;
	padding: 1rem 1.4rem;
	@include bp(sm-max) {
		min-height: 8rem;
		padding: 0.4rem 1.4rem;
	}
}

.primary-link {
	font-family: $mediumFont;
	line-height: 2rem;
	color: $orange;
	@extend .transition;
	&:hover {
		opacity: 0.8;
	}
	&.lg {
		font-size: 1.8rem;
		line-height: 2.4rem;
		font-family: $semiboldFont;
		@include bp(sm-max) {
			font-size: 1.6rem;
		}
	}
}

.orange-btn {
	height: 4.4rem;
	background-color: $orange;
	color: $white;
	font-size: 1.8rem;
	line-height: 3.6rem;
	font-family: $semiboldFont;
	padding: 0 2rem;
	border: 0.1rem solid $orange;
	@extend .transition;
	-webkit-appearance: none;
	border-radius: 0.6rem;
	display: inline-block;
	text-align: center;
	@include bp(sm-max) {
		height: 4rem;
		font-size: 1.4rem;
	}
	&:hover {
		background-color: darken($orange, 10%);
		border-color: darken($orange, 10%);
		color: $white;
	}
	&.disabled,
	&[disabled] {
		background: $lightOrange;
		border-color: $lightOrange;
		cursor: not-allowed;
		&:hover {
			background-color: $lightOrange;
			border-color: $lightOrange;
		}
	}
	&.sm {
		font-size: 1.4rem;
		height: 4rem;
	}
	&.is-bordered {
		background-color: transparent;
		color: $orange;
		&:hover {
			background-color: $orange;
			color: $white;
		}
		&.black-border {
			border-color: $black1;
			color: $black1;
			&:hover {
				background-color: $black1;
				color: $white;
			}
		}
	}
	&.is-white {
		background-color: $white;
		border-color: $white;
		color: $black1;
		&:hover {
			background-color: $white;
			border-color: $white;
			color: $black1;
			opacity: 0.8;
		}
	}
	&.icon-btn {
		width: 4.4rem;
		height: 4.4rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
	}
}

.form-group {
	margin-bottom: 2.5rem;
	@include bp(sm-max) {
		margin-bottom: 1.5rem;
	}
	&.has-error {
		.form-control {
			border-color: $red !important;
		}
		.input-hint {
			color: $red;
		}
	}
	> label {
		font-family: $mediumFont;
		display: block;
		margin-bottom: 0.7rem;
		font-size: 1.6rem;
		line-height: 2.4rem;
		.required {
			color: $orange;
		}
	}
	.has-postfix {
		position: relative;
		.form-control {
			padding-right: 7.5rem;
			.rtl & {
				padding-left: 7.5rem;
				padding-right: 1.4rem;
			}
		}
		.primary-link {
			position: absolute;
			top: 0.9rem;
			right: 1.5rem;
			z-index: 1;
			font-size: 1.6rem;
			.rtl & {
				right: auto;
				left: 1.5rem;
			}
		}
	}
}

.input-hint {
	margin-top: 0.6rem;
	display: block;
	color: $darkGrey;
	line-height: 2.2rem;
	@include bp(sm-max) {
		margin-top: 0.3rem;
		line-height: 2rem;
	}
}

.password-field {
	position: relative;
	&.pwd-visible {
		.pwd-icon {
			.eye {
				display: block;
			}
			.eye-off {
				display: none;
			}
		}
	}
	.form-control {
		padding-right: 4.5rem;
		.rtl & {
			padding-right: 1.4rem;
			padding-left: 4.5rem;
		}
	}
	.pwd-icon {
		position: absolute;
		right: 1.4rem;
		top: 1rem;
		line-height: 0;
		cursor: pointer;
		@extend .transition;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 2.4rem;
		width: 2.4rem;
		@include bp(sm-max) {
			top: 0.8rem;
		}
		.rtl & {
			right: auto;
			left: 1.4rem;
		}
		&:hover {
			opacity: 0.8;
		}
		.eye {
			display: none;
		}
		.eye-off {
			display: block;
		}
	}
}

.mob-form-field {
	display: flex;
	justify-content: space-between;
	&.lg {
		.custom-select.dk-select {
			width: 14.2rem;
			@include bp(sm-max) {
				width: 9.6rem;
			}
		}
		.form-control {
			width: calc(100% - 15.2rem);
			@include bp(sm-max) {
				width: calc(100% - 10.2rem);
			}
		}
	}
	.custom-select.dk-select {
		width: 10.2rem;
		.dk-selected {
			width: 100%;
		}
	}
	.form-control {
		width: calc(100% - 11.2rem);
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}

.otp-wrapper {
	display: flex;
	justify-content: space-between;
	max-width: 25.3rem;
	margin: 0 auto;
	.form-control {
		text-align: center;
		border: 0;
		border-radius: 0;
		border-bottom: 0.1rem solid $darkGrey2;
		width: 4.6rem;
		padding-left: 0;
		padding-right: 0;
		font-family: $mediumFont;
	}
}

.dk-select {
	width: 100%;
	&.dk-select-open-down,
	&.dk-select-open-up {
		.dk-selected {
			&:after {
				@include rotate(180deg);
			}
		}
	}
	&.dk-select-disabled {
		opacity: 1;
		.dk-selected {
			background: $grey;
			&:hover {
				border-color: $darkGrey2;
			}
		}
	}
	&.borderless {
		.dk-selected {
			color: $darkGrey;
			padding: 0;
			padding-right: 2.6rem;
			height: auto;
			border: 0;
			font-family: $regularFont;
			.rtl & {
				padding-right: 0;
				padding-left: 2.6rem;
			}
			&::after {
				content: "";
				height: 2.4rem;
				width: 2.4rem;
				background-image: url("../images/down-arrow-grey.svg");
				background-size: 1.1rem;
				background-repeat: no-repeat;
				background-position: center;
				top: 0.3rem;
				right: 0;
				.rtl & {
					right: auto;
					left: 0;
				}
			}
		}
	}
	&.sm {
		.dk-selected {
			padding: 0.6rem 3.3rem 1rem 0.9rem;
			height: 4rem;
			&:after {
				top: 1.6rem;
				right: 1.5rem;
			}
		}
	}
	.dk-selected {
		@extend .form-control;
		padding-top: 0.7rem;
		padding-right: 3.3rem;
		@include bp(sm-max) {
			padding-top: 0.5rem;
		}
		.rtl & {
			padding-right: 1.4rem;
			padding-left: 3.3rem;
		}
		&:hover {
			border-color: $darkGrey2;
		}
		&:focus {
			border-color: $darkGrey2;
		}
		&:before {
			display: none;
		}
		&::after {
			border: 0;
			margin: 0;
			width: 1.1rem;
			height: 0.6rem;
			position: absolute;
			top: 1.8rem;
			right: 2rem;
			@extend .transition;
			background-image: url("../images/down-arrow.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 1.1rem;
			@include bp(sm-max) {
				top: 1.6rem;
				right: 1.2rem;
			}
			.rtl & {
				left: 2rem;
				right: auto;
			}
		}
	}
	.dk-select-options {
		border-color: $darkGrey2 !important;
		padding: 0;
		max-height: 17rem;
		z-index: 2;
	}
	.dk-option {
		color: $black1;
		padding: 0.7rem 0.7rem 1.1rem;
		font-family: $regularFont;
		font-size: 1.4rem;
		&.dk-option-highlight,
		&.dk-option-selected {
			background-color: $grey;
			color: $black1;
		}
	}
}

.select2-container {
	display: block;
	width: 100% !important;
	&.select2-container--open {
		.select2-selection--single {
			.select2-selection__arrow {
				&::after {
					@include rotate(180deg);
				}
			}
		}
	}
	.select2-selection--single {
		@extend .form-control;
		padding-right: 3rem;
		.select2-selection__rendered {
			padding: 0;
			color: $black1;
			line-height: 3.2rem;
			@include bp(sm-max) {
				line-height: 2.8rem;
			}
		}
		.select2-selection__arrow {
			top: 0;
			bottom: 0;
			width: 2.6rem;
			height: auto;
			right: 0;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: calc(50% - 0.35rem);
				border: 0;
				margin: 0;
				width: 1.4rem;
				height: 0.7rem;
				background-image: url("../images/down-arrow.svg");
				@extend .bg-props;
				@extend .transition;
				background-size: 1.4rem !important;
				line-height: 0;
			}
			b {
				display: none;
			}
		}
	}
}

.select2-dropdown {
	border-color: $darkGrey2;
	.select2-dropdown {
		z-index: 4;
	}
	.select2-search--dropdown {
		padding: 0.7rem 1rem;
		.select2-search__field {
			border: 0.1rem solid $darkGrey2;
			padding: 0 1rem 0.25rem;
			color: $black1;
			font-size: 1.6rem;
			line-height: 1.9rem;
			height: 3.5rem;
		}
	}
	.select2-results__option {
		padding: 0.7rem 1.3rem 1.1rem;
		font-size: 1.6rem;
		line-height: 1;
		&.select2-results__option--selectable {
			&.select2-results__option--highlighted {
				background: $grey;
				color: $black1;
			}
			&.select2-results__option--selected {
				background: $grey;
				color: $black1;
			}
		}
	}
}

.custom-checkbox-wrapper {
	display: flex;
	flex-wrap: wrap;
	@include bp(xs-max) {
		margin: 0 -0.6rem;
	}
	&.auto-width {
		.checkout-outer {
			min-width: inherit;
			flex: inherit;
			label {
				padding: 0 2.1rem;
				@include bp(sm-max) {
					padding: 0 1.5rem;
					font-size: 1.4rem;
					height: 4rem;
					line-height: 3.5rem;
				}
			}
		}
	}
	.checkout-outer {
		margin-right: 2rem;
		position: relative;
		min-width: 12rem;
		@include bp(xs-max) {
			padding: 0 0.6rem;
			margin-right: 0;
			flex: 1;
			min-width: inherit;
		}
		&:first-child {
			.rtl & {
				margin-right: 0 !important;
			}
		}
		&:last-child {
			margin-right: 0;
			.rtl & {
				margin-right: 2rem;
				@include bp(xs-max) {
					margin-right: 0;
				}
			}
		}
		.hidden-input {
			&:checked {
				~ label {
					color: $orange;
					border-color: $orange;
				}
			}
		}
		label {
			font-size: 1.6rem;
			line-height: 2.4rem;
			font-family: $mediumFont;
			color: $darkGrey;
			border: 0.1rem solid $darkGrey2;
			border-radius: 0.4rem;
			height: 4.8rem;
			line-height: 4.1rem;
			padding: 0 1rem;
			display: block;
			text-align: center;
			@extend .transition;
			@include bp(sm-max) {
				height: 4.2rem;
				line-height: 3.7rem;
			}
		}
	}
}

.dp-upload-wrapper {
	display: flex;
	height: 10rem;
	width: 10rem;
	filter: drop-shadow(0 0.1rem 0.4rem rgba($black, 0.1));
	background: $white;
	border-radius: 50%;
	padding: 0.4rem;
	@include bp(smd-max) {
		margin-left: auto;
		margin-right: auto;
	}
	.dp-thumb {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	.edit-dp {
		position: absolute;
		right: 0;
		bottom: 0;
		background: $black1;
		border-radius: 50%;
		width: 3.7rem;
		height: 3.7rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		cursor: pointer;
		.rtl & {
			right: auto;
			left: 0;
		}
		@include bp(sm-max) {
			height: 3rem;
			width: 3rem;
			img {
				width: 1.6rem;
			}
		}
	}
}

.radio-wrapper {
	&.inline {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: -1.6rem;
		.radio-outer {
			margin: 0 1.6rem 1.6rem 0;
			.rtl & {
				margin-right: 0;
				margin-left: 1.6rem;
			}
		}
	}
	.radio-outer {
		position: relative;
		margin-bottom: 1.6rem;
		&:last-child {
			margin-bottom: 0;
		}
		&.no-label {
			line-height: 0;
			label {
				min-height: 2rem;
				padding: 0 !important;
				min-width: 2rem;
				&:before {
					top: 0;
				}
				&:after {
					top: 0.3rem;
				}
			}
		}
		.hidden-input {
			&:checked {
				~ label {
					&:before {
						border-color: $orange;
					}
					&:after {
						@include scale(1);
						opacity: 1;
					}
				}
			}
		}
		label {
			padding-left: 2.8rem;
			position: relative;
			.rtl & {
				padding-left: 0;
				padding-right: 2.8rem;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0.5rem;
				height: 2rem;
				width: 2rem;
				border: 0.1rem solid $darkGrey2;
				border-radius: 50%;
				@extend .transition;
				@include bp(sm-max) {
					top: 0.3rem;
				}
				.rtl & {
					left: auto;
					right: 0;
				}
			}
			&::after {
				content: "";
				position: absolute;
				left: 0.3rem;
				top: 0.8rem;
				height: 1.4rem;
				width: 1.4rem;
				background-color: $orange;
				border-radius: 50%;
				@extend .transition;
				@include scale(0.5);
				opacity: 0;
				@include bp(sm-max) {
					top: 0.6rem;
				}
				.rtl & {
					left: auto;
					right: 0.3rem;
				}
			}
		}
	}
}

.checkbox-list {
	.custom-secondary-checkbox {
		margin-bottom: 1.2rem;
		@include bp(sm-max) {
			margin-bottom: 1.3rem;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.custom-secondary-checkbox {
	position: relative;
	font-size: 1.4rem;
	line-height: 2.4rem;
	.hidden-input {
		&:checked {
			~ label {
				&::after {
					border-color: $orange;
					background-color: $orange;
				}
				&::after {
					opacity: 1;
				}
			}
		}
	}
	label {
		padding-left: 2.8rem;
		.rtl & {
			padding-left: 0;
			padding-right: 2.8rem;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0.5rem;
			height: 2rem;
			width: 2rem;
			border-radius: 0.2rem;
			@extend .transition;
			.rtl & {
				left: auto;
				right: 0;
			}
		}
		&::before {
			background-color: $white;
			border: 0.2rem solid $darkGrey2;
		}
		&:after {
			background-image: url("../images/tick.svg");
			background-size: 1.2rem;
			background-repeat: no-repeat;
			background-position: center;
			opacity: 0;
		}
	}
}

.map-selection {
	position: relative;
	padding-bottom: 20.86%;
	@include bp(lg-max) {
		padding-bottom: 25%;
	}
	@include bp(sm-max) {
		padding-bottom: 45%;
	}
	@include bp(xs-max) {
		padding-bottom: 62.5%;
	}
	.map-frame {
		@extend .absolute-pos;
		border: 0;
	}
}

.qty-counter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 15.4rem;
	max-width: 100%;
	border: 0.1rem solid $darkGrey2;
	border-radius: 0.6rem;
	padding: 0.7rem 0.9rem;
	&.borderless {
		padding: 0;
		border: 0;
		width: 10.3rem !important;
	}
	.plus-minus {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		line-height: 0;
		cursor: pointer;
		width: 2.4rem;
		min-width: 2.4rem;
		&.disabled {
			cursor: not-allowed;
			.default-img {
				display: none;
			}
			.disabled-img {
				display: block;
			}
		}
		.disabled-img {
			display: none;
		}
	}
	.form-control {
		border: 0;
		padding: 0 1rem;
		height: 2.4rem;
		text-align: center;
		width: 7rem;
	}
}

.download-invoice {
	display: flex;
	align-items: center;
	font-family: $mediumFont;
	color: $black1;
	font-size: 1.6rem;
	line-height: 2.4rem;
	.icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 2.2rem;
		width: 2.2rem;
		margin-right: 0.9rem;
		.rtl & {
			margin-right: 0;
			margin-left: 0.9rem;
		}
	}
	.right-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 2.4rem;
		width: 2.4rem;
		margin-left: auto;
		position: relative;
		top: 0.2rem;
		.rtl & {
			margin-left: 0;
			margin-right: auto;
		}
	}
}
